import React, { useState } from "react";
import "./AuctionDetails.css";
import DetailsAndBidsBtn from "./DetailsAndBidsBtn";
import Details from "./Details/Details";
import Bids from "./Bids/Bids";
import Headings from "../../Headings/Headings";

function AuctionDetails() {
  const [isActive, setIsActive] = useState(true);
  const [auctionDetailsBids, setAuctionDetailsBids] =
    useState("/ Auctions Details");

  const handleButtonClick = (isDetailsActive) => {
    setIsActive(isDetailsActive);
    setAuctionDetailsBids(
      isDetailsActive ? "/ Auctions Details" : "/ Auction Bids"
    );
  };

  return (
    <>
      <div className="auction-details-box gilroy">
        <Headings headings="Auctions" auctionDetailsBids={auctionDetailsBids} />
        <div>
          <DetailsAndBidsBtn
            isActive={isActive}
            onButtonClick={handleButtonClick}
          />
        </div>
        <div>{isActive ? <Details /> : <Bids />}</div>
      </div>
    </>
  );
}

export default AuctionDetails;
