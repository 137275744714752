import React from "react";
import "./Dashboard.css";
// import Carousel from "../Home/Carousel/Carousel";
// import CarouselPage from "../Home/Carousel/Carousel";
import QuickAction from "./QuickAction/QuickAction";
import RecentOrder from "./RecentOrder/RecentOrder";
import RecentAuctions from "./RecentAuctions/RecentAuctions";

import DashboardCarousel from "./DashboardCarousel/DashboardCarousel";
import AnalyticsSection from "./AnalyticsSection/AnalyticsSection";

function Dashboard() {
  return (
    <>
      <div className="dashboar-box ">
        <DashboardCarousel />
        <div className="dashboard_contents">
          <QuickAction />
          <RecentOrder />
          <RecentAuctions />
          <hr className="my-5" />
          <AnalyticsSection />
        </div>
      </div>
    </>
  );
}

export default Dashboard;
