import React, { useEffect, useState } from "react";
import "./SubCategories.css";
import ExpandCircleDownRoundedIcon from "@mui/icons-material/ExpandCircleDownRounded";
import { Button } from "@mui/material";
import ReactPaginate from "react-paginate";
import { subCategoriesData } from "./subCategoriesData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const itemsPerPage = 5;

function SubCategories() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [currentPage, setCurrentPage] = useState(0);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const offset = currentPage * itemsPerPage;
  const currentItems = subCategoriesData.slice(offset, offset + itemsPerPage);

  return (
    <>
      <div>
        {currentItems &&
          currentItems.map((item) => (
            <div
              key={item.id}
              className="sub--categories__container mt-4 border p-3 rounded d-flex align-items-center justify-content-between"
            >
              <div className="d-flex align-items-center gap-4">
                <div className="sub--categories__img-box border">
                  <img
                    src={item.img}
                    alt={item.title}
                    className="sub-cate__img"
                  />
                </div>
                <div>
                  <h3 className="sub--categories__title gilroy fs-3">
                    {item.title}
                  </h3>
                  <p className="sub--categories__sub-title">{item.subtitle}</p>
                </div>
              </div>
              <div className="expand--page_btn p-4">
                <Button
                  onClick={() => navigate("/services_provided")}
                  className="provided--services__expand py-3 rounded-circle"
                >
                  <ExpandCircleDownRoundedIcon className="expand--page_icon" />
                </Button>
              </div>
            </div>
          ))}
      </div>
      <ReactPaginate
        previousLabel={
          <FontAwesomeIcon
            icon={faChevronLeft}
            style={{ color: "#001b3d", fontSize: "16px" }}
          />
        }
        nextLabel={
          <FontAwesomeIcon
            icon={faChevronRight}
            style={{ color: "#001b3d", fontSize: "16px" }}
          />
        }
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={Math.ceil(subCategoriesData.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
        pageClassName="pt-1"
        previousClassName=" pt-1"
        nextClassName=" pt-1"
      />
    </>
  );
}

export default SubCategories;
