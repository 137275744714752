export const baseUrl = "http://65.1.109.1/api";

// seller profile
export const profileBusinessAPI = baseUrl + "/profile/businessProfile";
export const registerDataAPI = baseUrl + "/profile/companyDetails";

// seller profile category
export const profileCategoriesAPI = baseUrl + "/profile/categories";
export const createServices = baseUrl + "/service/createServices";
export const socialLinks = baseUrl + "/profile/socialLink";
// Services--------------
export const allServicesAPI = baseUrl + "/service/allServices";
export const updateServicesAPI = baseUrl + "/service/updateServices";
