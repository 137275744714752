import React, { useState } from "react";
import "./Login.css";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import Email from "./Email/Email";
import Otp from "./Otp/Otp";
import image from "./login/login.png";
import { Link } from "react-router-dom";
import { Button, useMediaQuery } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Login() {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [showOtp, setShowOtp] = useState(false);
  const [emailId, setEmailId] = useState("");
  const [error, setError] = useState("");
  const [verifiedOtp, setVerifiedOtp] = useState(false);
  const [otpError, setOtpError] = useState("");
  const [emailMsg, setEmailMsg] = useState("");
  const navigate = useNavigate();

  // Screen Size for responsiveness
  const isLargeScreen = useMediaQuery("(max-width: 1200px)");
  const isImageShow = useMediaQuery("(max-width: 664px) ");
  const isVerySmallScreen = useMediaQuery("(max-width: 568px) ");

  const supplierApi = "http://65.1.109.1/api/authentication/supplier";
  const verifyOtpApi =
    "http://65.1.109.1/api/authentication/supplier/verifyOtp";

  const handleGetOtp = async () => {
    if (!email) {
      setError("Please enter your email.");
    } else if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
    } else {
      setError("");
      try {
        const response = await axios.post(supplierApi, { email });

        if (response && response.status === 200) {
          setEmailId(response.data.data);
          toast.success(response.data.msg);
          setError("");
          setTimeout(() => setShowOtp(true), 2000);
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          const emailError = error.response.data.error;
          toast.error(emailError);
          setEmailMsg("");
          setShowOtp(false);
        }
      }
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleOtpChange = (event, index) => {
    const value = event.target.value;
    if (value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move to the next input field if the current one is filled
      if (value !== "" && index < 5) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  const handleVerifyOtp = async () => {
    const otpCode = otp.join("");
    try {
      const response = await axios.post(verifyOtpApi, {
        id: emailId,
        otp: otpCode,
      });
      if (response && response.status === 200) {
        if (response.data.status === 1) {
          setVerifiedOtp(true);
          toast.success(response.data.message);
          setOtpError("");
          localStorage.setItem(
            "loginCredentials",
            JSON.stringify(response.data)
          );
          setTimeout(() => {
            navigate("/profile_setup");
            window.location.reload();
          }, 2000);
        } else {
          navigate("/seller-profile");
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setVerifiedOtp(false);
        toast.error(error.response.data.error);
      }
    }
  };

  return (
    <div className="login-page text-dark mt-4 gap-3 gap-md-5 d-flex">
      <div style={{ display: isImageShow ? "none" : "block" }} className="mt-5">
        <img src={image} alt="login" className="login-img" />
      </div>
      <div className="form d-flex flex-column mt-5 ms-3 ms-sm-4 justify-content-center p-0 p-sm-2 p-md-3">
        <div className="heading my-5 d-block d-sm-flex">
          <ArrowBackRoundedIcon
            style={{
              fontSize: "35px",
              cursor: "pointer",
            }}
            className="back-arrow mt-1"
          />
          <h1 className=" login_heading fw-bold gilroy ms-0 ms-sm-3">
            <span className="welcome">Welcome Back!</span> Please Login
          </h1>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center w-100 pe-3 pe-sm-5">
          <div className="d-block w-100">
            {!showOtp ? (
              <Email
                email={email}
                setEmail={setEmail}
                error={error}
                emailMsg={emailMsg}
              />
            ) : (
              <Otp
                otp={otp}
                handleOtpChange={handleOtpChange}
                error={error}
                verifiedOtp={verifiedOtp}
                otpError={otpError}
              />
            )}
          </div>
          <div className="login_btn mt-4 mt-xl-5  ">
            {!showOtp ? (
              <Button
                onClick={handleGetOtp}
                variant="contained"
                fullWidth
                className=" px-3 py-2"
                style={{
                  backgroundColor: "rgba(19, 101, 175, 1)",
                  color: "#fff",
                  textTransform: "none",
                  fontSize: isLargeScreen ? "16px" : "18px",
                }}
              >
                Get OTP
              </Button>
            ) : (
              <Button
                type="submit"
                variant="contained"
                fullWidth
                className=" px-3 py-2"
                onClick={handleVerifyOtp}
                style={{
                  backgroundColor: "rgba(19, 101, 175, 1)",
                  color: "#fff",
                  textTransform: "none",
                }}
              >
                Verify OTP
              </Button>
            )}
          </div>

          <div className="redirect-register-page d-flex justify-content-center mt-5 mt-md-4 ">
            <p
              style={{ fontSize: isVerySmallScreen ? "13px" : "16px" }}
              className="mt-3 fw-bold"
            >
              Not registered with us?
              <span>
                <Link
                  to={"/registration"}
                  className="fw-bold"
                  style={{
                    color: "rgba(19, 101, 175, 1)",
                    textDecoration: "none",
                    fontWeight: "500",
                  }}
                >
                  &nbsp;Create an account
                </Link>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
