import React from "react";
import OrderReceived from "./OrderReceived/OrderReceived";
import Earnings from "./Earnings/Earnings";

const AnalyticsSection = () => {
  return (
    <div>
      <div className="earnings-heading-seeAllButton d-flex justify-content-between">
        <h1 style={{ fontSize: "45px" }} className="heading gilroy mt-1">
          Analytics
        </h1>
      </div>
      <OrderReceived />
      <Earnings />
    </div>
  );
};

export default AnalyticsSection;
