import React from "react";
import "./OrderPageProfileStatus.css";
import { Avatar, Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments } from "@fortawesome/free-solid-svg-icons";

const OrderPageProfileStatus = () => {
  return (
    <>
      <section className="order_page__profile__status pt-3">
        <div className="order__page_content mt-5">
          <div className="d-flex gap-4 align-items-center">
            <h1 className="order_title gilroy-regular">
              Pest Control Services at Arera Colony
            </h1>
            <p className="order_status p-2 gilroy rounded-pill">
              Status: In-Progress
            </p>
          </div>
          <div className="d-flex" style={{ gap: "5.5rem" }}>
            <p className="order_id gilroy-regular">Order ID: 1638910923</p>
            <p className="order_placed gilroy-regular">
              Order placed at: 20th November, 2023 7:53 p.m.
            </p>
          </div>
        </div>
        <div className="order_profile__content d-flex gap-3 mt-4">
          <div className="d-flex gap-3 align-items-centr">
            <Avatar
              alt="Remy Sharp"
              src="/static/images/avatar/1.jpg"
              sx={{ width: 22, height: 22, marginTop: "2px" }}
            />
            <p className="order_name gilroy-regular ">Dainik Pest Controls</p>
          </div>
          <p className="order_view__profile p-3 rounded-pill">View Profile</p>
        </div>
        <Button variant="contained" className="d-flex gap-2 align-items-center">
          <FontAwesomeIcon
            icon={faComments}
            style={{ fontSize: "15px", transform: "scaleX(-1)" }}
          />
          <p
            style={{ fontSize: "16px", color: "#ffffff", lineHeight: "20px" }}
            className="m-0 gilroy"
          >
            Chat
          </p>
        </Button>
      </section>
    </>
  );
};

export default OrderPageProfileStatus;
