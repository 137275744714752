import React, { useState } from "react";
import { TextField } from "@mui/material";
import "./ProductInfo.css";

const ProductInfo = () => {
  return (
    <>
      <div className="product_info_section">
        <div className="product_name__mrp d-flex justify-content-between gap-4 mt-4">
          <div className="w-100">
            <h3 className="label-text gilroy">Product Title</h3>
            <TextField
              fullWidth
              size="small"
              sx={{ backgroundColor: "#ffffff" }}
            />
          </div>
          <div className="w-100">
            <h3 className="label-text gilroy">Product MRP</h3>
            <TextField
              fullWidth
              size="small"
              sx={{ backgroundColor: "#ffffff" }}
            />
          </div>
        </div>
        <div className="product_descrb__final_price__avai_stack d-flex justify-content-between gap-4 mt-4">
          <div className="w-100">
            <h3 className="label-text gilroy">Product Description</h3>
            <TextField multiline fullWidth size="small" rows={5} />
          </div>
          <div className="w-100 d-flex flex-column gap-4">
            <div className="w-100">
              <h3 className="label-text gilroy">Product Final Price</h3>
              <TextField fullWidth size="small" />
            </div>
            <div className="w-100">
              <h3 className="label-text gilroy">Available Stock</h3>
              <TextField fullWidth size="small" />
            </div>
          </div>
        </div>
        <div className="product_features__specifications d-flex justify-content-between gap-4 mt-4">
          <div className="w-100">
            <h3 className="label-text gilroy">Product Features</h3>
            <TextField
              multiline
              fullWidth
              size="small"
              rows={5}
              sx={{ backgroundColor: "#ffffff" }}
            />
          </div>
          <div className="w-100">
            <h3 className="label-text gilroy">Product Specifications</h3>
            <TextField
              multiline
              fullWidth
              size="small"
              rows={5}
              sx={{ backgroundColor: "#ffffff" }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductInfo;
