import React, { useState } from "react";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Button,
  useMediaQuery,
} from "@mui/material";

const AdditionalDetails = ({ onSave }) => {
  // Screen size for responsiveness------------------
  const isLargeScreen = useMediaQuery("(max-width: 992px)");
  const isMinSmallScreen = useMediaQuery("(min-width: 568px) ");

  // useStates
  const [isStartUp, setIsStartUp] = useState("");
  const [udyamRegistration, setUdyamRegistration] = useState("");
  const [bidParticipation, setBidParticipation] = useState("");

  // handle inputs
  const handleDpiitChange = (event) => {
    setIsStartUp(event.target.value);
  };

  const handleUdyamChange = (event) => {
    setUdyamRegistration(event.target.value);
  };

  const handleBidChange = (event) => {
    setBidParticipation(event.target.value);
  };

  // handling save button
  const handleSave = () => {
    const addData = {
      prefences: {
        isStartUp,
        msme: udyamRegistration,
        bid: bidParticipation,
      },
    };
    onSave(addData);
  };

  const formControlStyle =
    "d-flex flex-column flex-sm-row gap-1 gap-sm-5 mt-4 align-items-sm-center";
  const styleLabel = {
    fontSize: isLargeScreen ? "14px" : "16px",
    fontWeight: 500,
    width: isMinSmallScreen ? "70%" : "100%",
  };

  return (
    <>
      <div className="additional_details__container mx-0 my-1 w-100 h-auto">
        <p
          style={{ fontSize: isLargeScreen ? "16px" : "18px" }}
          className="text-center gilroy"
        >
          Please Select Your Preference.
        </p>
        <div>
          <FormControl sx={{ width: "100%" }}>
            <div className={`${formControlStyle}`}>
              <label
                style={styleLabel}
                htmlFor="dpiit-registration"
                className=""
              >
                Are you registered with DPIIT as StartUp?
              </label>
              <RadioGroup
                row
                aria-labelledby="additional-details"
                name="isStartUp"
                id="dpiit-registration"
                value={isStartUp}
                onChange={handleDpiitChange}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio size="small" />}
                  label={
                    <p style={styleLabel} className="m-0">
                      Yes
                    </p>
                  }
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="no"
                  control={<Radio size="small" />}
                  label={
                    <p style={styleLabel} className="m-0">
                      No
                    </p>
                  }
                  labelPlacement="end"
                />
              </RadioGroup>
            </div>
            <div className={`${formControlStyle}`}>
              <label
                style={styleLabel}
                htmlFor="udyam-registration"
                className="p-0 m-0 "
              >
                Do you have Udyam Registration number certified by MSME?
              </label>
              <RadioGroup
                row
                aria-labelledby="additional-details"
                name="udyamRegistration"
                id="udyam-registration"
                value={udyamRegistration}
                onChange={handleUdyamChange}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio size="small" />}
                  label={
                    <p style={styleLabel} className="m-0">
                      Yes
                    </p>
                  }
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="no"
                  control={<Radio size="small" />}
                  label={
                    <p style={styleLabel} className="m-0">
                      No
                    </p>
                  }
                  labelPlacement="end"
                />
              </RadioGroup>
            </div>
            <div className={`${formControlStyle}`}>
              <label style={styleLabel} htmlFor="bid-participate" className="">
                Do you want to participate in Bid?
              </label>
              <RadioGroup
                row
                aria-labelledby="additional-details"
                name="bidParticipation"
                id="bid-participate"
                value={bidParticipation}
                onChange={handleBidChange}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio size="small" />}
                  label={
                    <p style={styleLabel} className="m-0">
                      Yes
                    </p>
                  }
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="no"
                  control={<Radio size="small" />}
                  label={
                    <p style={styleLabel} className="m-0">
                      No
                    </p>
                  }
                  labelPlacement="end"
                />
              </RadioGroup>
            </div>
          </FormControl>
        </div>
        <Button
          variant="contained"
          onClick={handleSave}
          sx={{ textTransform: "none" }}
          className="mt-4 float-end"
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default AdditionalDetails;
