import React, { useState } from "react";
import "./AddProducts.css";
import { Button } from "@mui/material";
import { ArrowBackRounded, CloudUploadOutlined } from "@mui/icons-material";
import ProductInfo from "./ProductInfo/ProductInfo";

const AddProducts = () => {
  const [file, setFile] = useState(null);

  // Handling States
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  const handleFileDrop = (e) => {
    e.preventDefault();
    setFile(e.dataTransfer.files[0]);
  };
  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const handleUploadClick = () => {
    // Handle the file upload logic here
    // console.log("Uploading file:", file);
  };

  return (
    <>
      <div className="add_products_container">
        <div className="d-flex gap-3">
          <ArrowBackRounded className="back-arrow mt-2 fs-2 fw-bold" />
          <div className="">
            <h1 className="heading fw-bold gilroy">Add Products</h1>
            <p
              style={{ fontSize: "16px", fontWeight: 600, color: "#6A7D9F" }}
              className="m-0 gilroy"
            >
              Home / Products & Services / Add Products
            </p>
          </div>
        </div>
        <section className="upload_products_img mt-5">
          <h1 className="heading fw-bold gilroy ">Upload Product Images</h1>
          <div className="file-upload-container mt-5">
            <div
              className="file-drop-zone d-flex px-4 py-3 gap-5 align-items-center"
              onDrop={handleFileDrop}
              onDragOver={handleDragOver}
            >
              <div className="file-drop-text d-flex gap-3">
                <CloudUploadOutlined
                  sx={{ color: "#999999", fontSize: "50px" }}
                />
                <div>
                  <p className="upload_para gilroy-regular ps-1">
                    Select a file or drag and drop here
                  </p>
                  <p
                    style={{ color: "#999999" }}
                    className="upload_para gilroy-regular m-0 p-0"
                  >
                    JPG, PNG or PDF, file size no more than 10MB
                  </p>
                </div>
              </div>
              <input
                type="file"
                onChange={handleFileChange}
                className="file-input"
              />
              <Button
                variant="contained"
                onClick={handleUploadClick}
                sx={{
                  color: "#ffffff",
                  backgroundColor: "rgba(74, 123, 157, 1)",
                  padding: "10px 20px",
                  textDecoration: "none",
                }}
                className="gilroy-regular "
              >
                Upload
              </Button>
            </div>
          </div>
        </section>

        <section className="product_info_sections mt-5">
          <h1 className="heading fw-bold gilroy ">Product Information</h1>
          <div>
            <ProductInfo />
          </div>
        </section>
        <div className="mt-5">
          <Button
            variant="contained"
            sx={{ textTransform: "none", fontSize: "18px" }}
            className="gilroy"
          >
            Confirm
          </Button>
        </div>
      </div>
    </>
  );
};

export default AddProducts;
