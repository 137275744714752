import React from "react";
import "./RecentOrder.css";
import OrderCarousel from "./OrderCarousel/OrderCarousel";
import { Button } from "@mui/material";

function RecentOrder() {
  return (
    <>
      <div className="recent-order-box mt-5">
        <div className="recent-orders-heading-seeAllButton d-flex justify-content-between">
          <h1 style={{ fontSize: "45px" }} className="heading gilroy mt-1">
            Recent Orders
          </h1>
          <Button
            style={{
              backgroundColor: "rgba(208, 223, 255, 1)",
              color: "rgba(0,0,0,1)",
            }}
            className="text-capitalize fs-5 mb-4 mt-1 p-1 px-3 py-2"
          >
            See All
          </Button>
        </div>
        <div className="recent-orders-carousel mt-1">
          <OrderCarousel />
        </div>
      </div>
    </>
  );
}

export default RecentOrder;
