export const ordersContent = [
  {
    id: 1,
    title: "Pest Control Services at Arera Colony",
    orderTime: "2 mins ago",
    area: "General Pest Control, 1 BHK (1000-2000 sq ft)",
    status: "In-Progress",
    username: "Manjoo Shrivastava",
    location: "Arera colony, Bhopal, 462001",
    serviceOrdered: "Dainik Pest Controls",
    dateTime: "24th Mar, 2023 | 4-6 p.m.",
    orderId: "903483290",
  },
  {
    id: 2,
    title: "General Pest Control in Area colony",
    orderTime: "2 mins ago",
    area: "General Pest Control, 1 BHK (1000-2000 sq ft)",
    status: "Completed",
    username: "Manjoo Shrivastava",
    location: "Arera colony, Bhopal, 462001",
    serviceOrdered: "Dainik Pest Controls",
    dateTime: "24th Mar, 2023 | 4-6 p.m.",
    orderId: "903483290",
  },
  {
    id: 3,
    title: "General Pest Control in Area colony",
    orderTime: "2 mins ago",
    area: "General Pest Control, 1 BHK (1000-2000 sq ft)",
    status: "In-Progress",
    username: "Manjoo Shrivastava",
    location: "Arera colony, Bhopal, 462001",
    serviceOrdered: "Dainik Pest Controls",
    dateTime: "24th Mar, 2023 | 4-6 p.m.",
    orderId: "903483290",
  },
  {
    id: 4,
    title: "Pest Control Services at Arera Colony",
    orderTime: "2 mins ago",
    area: "General Pest Control, 1 BHK (1000-2000 sq ft)",
    status: "In-Progress",
    username: "Manjoo Shrivastava",
    location: "Arera colony, Bhopal, 462001",
    serviceOrdered: "Dainik Pest Controls",
    dateTime: "24th Mar, 2023 | 4-6 p.m.",
    orderId: "903483290",
  },
  {
    id: 5,
    title: "General Pest Control in Area colony",
    orderTime: "2 mins ago",
    area: "General Pest Control, 1 BHK (1000-2000 sq ft)",
    status: "In-Progress",
    username: "Manjoo Shrivastava",
    location: "Arera colony, Bhopal, 462001",
    serviceOrdered: "Dainik Pest Controls",
    dateTime: "24th Mar, 2023 | 4-6 p.m.",
    orderId: "903483290",
  },
  {
    id: 6,
    title: "General Pest Control in Area colony",
    orderTime: "2 mins ago",
    area: "General Pest Control, 1 BHK (1000-2000 sq ft)",
    status: "In-Progress",
    username: "Manjoo Shrivastava",
    location: "Arera colony, Bhopal, 462001",
    serviceOrdered: "Dainik Pest Controls",
    dateTime: "24th Mar, 2023 | 4-6 p.m.",
    orderId: "903483290",
  },
  {
    id: 7,
    title: "General Pest Control in Area colony",
    orderTime: "2 mins ago",
    area: "General Pest Control, 1 BHK (1000-2000 sq ft)",
    status: "In-Progress",
    username: "Manjoo Shrivastava",
    location: "Arera colony, Bhopal, 462001",
    serviceOrdered: "Dainik Pest Controls",
    dateTime: "24th Mar, 2023 | 4-6 p.m.",
    orderId: "903483290",
  },
];
