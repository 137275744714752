import React, { useState } from "react";
import pestImage from "../pestImage.png";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import { Button, Rating } from "@mui/material";
import ReactPaginate from "react-paginate";
import { bidderContent } from "./bidderContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const itemsPerPage = 4;

function BidderContainer() {
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const offset = currentPage * itemsPerPage;
  const currentItems = bidderContent.slice(offset, offset + itemsPerPage);

  return (
    <>
      <div>
        {currentItems &&
          currentItems.map((item) => (
            <div className="bidder-content mt-5  border p-4 rounded gilroy">
              <div className="image-and-bids-info d-flex gap-4">
                <img
                  src={pestImage}
                  alt={item.title}
                  className="rounded"
                  style={{ width: "9.125rem", height: "9.125rem" }}
                />
                <div className="bidder-info d-flex flex-column justify-content-between p-0 m-0 w-100 ">
                  <div className="title-location">
                    <div className="d-flex justify-content-between w-100 p-0">
                      <div className="d-flex  gap-2 ">
                        <h4 className="bid-title m-0 p-0">{item.title}</h4>
                        <div className=" d-flex flex-row align-items-center certify-check fw-bold p-1 text-center rounded">
                          <CheckCircleOutlineRoundedIcon
                            className="certify-check-icon"
                            sx={{ fontSize: 16 }}
                          />
                          <span
                            style={{ fontSize: 16 }}
                            className="certify-check"
                          >
                            &nbsp;{item.certify}
                          </span>
                        </div>
                        <div className="ratings-bids">
                          <Rating
                            sx={{ fontSize: "18px" }}
                            name="half-rating-read"
                            readOnly
                            defaultValue={item.defaultValue}
                            precision={0.5}
                          />
                        </div>
                      </div>
                      <div className="bidder-add-date ">
                        <span
                          style={{ fontSize: "14px" }}
                          className="text-lowercase gilroy"
                        >
                          {item.addTime} ago
                        </span>
                      </div>
                    </div>
                    <p
                      style={{
                        fontSize: "16px",
                        lineHeight: "18.83px",
                        fontWeight: 600,
                        color: "rgba(26, 27, 30, 1)",
                      }}
                      className="gilroy p-0 gilroy"
                    >
                      <FontAwesomeIcon
                        icon={faMapMarkerAlt}
                        style={{ color: "#1a1b1e" }}
                      />
                      &nbsp;{item.location}
                    </p>
                  </div>

                  <div className="d-flex justify-content-between align-items-center">
                    <div className="about d-flex flex-column">
                      <div className="d-flex gap-2  align-items-end">
                        <p className="bed-bug-termination rounded m-0 gilroy">
                          Bed Bug termination
                        </p>
                        <p className="pest-control rounded gilroy m-0">
                          Pest Control
                        </p>
                      </div>

                      <p
                        style={{ color: "rgba(106, 125, 159, 1)" }}
                        className=" m-0 mt-1"
                      >
                        <span
                          style={{ color: "rgba(26, 27, 30, 1)" }}
                          className="fw-bold gilroy p-0"
                        >
                          About: &nbsp;
                        </span>
                        {item.about}
                      </p>
                    </div>
                    <Button
                      sx={{
                        backgroundColor: "rgba(230, 255, 218, 1)",
                        color: "rgba(42, 153, 40, 1)",
                        fontSize: "16px",
                        padding: "6px 12px 6px 12px",
                        textTransform: "none",
                        borderRadius: "4px",
                        lineHeight: "18.83px",
                      }}
                      className="bids-price-button gilroy"
                    >
                      Bids:&nbsp;<span>&#8377;{item.bidAmount}</span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      <ReactPaginate
        previousLabel={
          // <ChevronBack color="#001b3d" width="18px" height="18px" />
          <FontAwesomeIcon icon={faChevronLeft} style={{ color: "#001b3d" }} />
        }
        nextLabel={
          // <ChevronForward color="#001b3d" width="18px" height="18px" />
          <FontAwesomeIcon icon={faChevronRight} style={{ color: "#001b3d" }} />
        }
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={Math.ceil(bidderContent.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
        pageClassName="pt-1"
        previousClassName=" pt-1"
        nextClassName=" pt-1"
      />
    </>
  );
}

export default BidderContainer;
