import React from "react";
import "./Reviews.css";
import ReviewProgressBar from "./ReviewProgressBar/ReviewProgressBar";
import CustomerReview from "./CustomerReviews/CustomerReview";

function Reviews() {
  return (
    <>
      <div className="reviews_container gilroy">
        <h4 className="reviews-heading gilroy">Reviews</h4>
        <div className="reviews-progress-bar-container py-4 my-5">
          <ReviewProgressBar />
        </div>
        <div className="customer-reviews-container ">
          <CustomerReview />
        </div>
      </div>
    </>
  );
}

export default Reviews;
