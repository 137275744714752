import React, { useState } from "react";
import "./Categories.css";
import ProductsFilter from "./ProductFilter/ProductFilter";
import ProductSort from "./ProductSort/ProductSort";
import { Row, Col } from "react-bootstrap";
import { Rating, Typography, useMediaQuery } from "@mui/material";
import ReactPaginate from "react-paginate";
import products from "./productData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const Categories = () => {
  const isLargeScreen = useMediaQuery("(max-width: 1024px)");

  // Pagination states
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 4;

  // Calculate products to display on the current page
  const offset = currentPage * itemsPerPage;
  const currentProducts = products.slice(offset, offset + itemsPerPage);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  return (
    <>
      <div className="my_products_categories_results__container">
        <h4 className="categories_heading gilroy">
          Showing All <strong>(9953)</strong> <span>Pest control services</span>
        </h4>
        <div className="d-flex justify-content-between mt-4">
          <ProductsFilter />
          <ProductSort />
        </div>
        <section className="product_card_section mt-4">
          <Row className="product_card__container">
            {currentProducts.map((product) => (
              <Col key={product.id} xs={12} sm={6} md={6} className="my-3">
                <div className="product_card__content border rounded p-3">
                  <div className="product_img_section">
                    <img
                      src={product.image}
                      alt={product.title}
                      className="product__img"
                    />
                  </div>
                  <div className="product_info_section mt-2">
                    <p className="product__title gilroy-regular">
                      {product.title}
                    </p>
                    <p className="product__quantity m-0 gilroy">
                      Min. Qty: {product.quantity}
                    </p>
                    <div className="ratings mt-2 d-flex gap-2 align-items-ceter">
                      <Rating
                        sx={{ fontSize: isLargeScreen ? "20px" : "24px" }}
                        name="half-rating-read"
                        readOnly
                        defaultValue={product.rating}
                        precision={0.5}
                      />
                      <Typography className="ratings__viewers gilroy pt-1">
                        ({product.reviews})
                      </Typography>
                    </div>
                    <div className="product__price_content d-flex gap-2 align-items-center mt-3">
                      <span className="orignal__prod_price text-decoration-line-through">
                        &#8377;{product.originalPrice}
                      </span>
                      <span className="disconted__prod_price">
                        &#8377;{product.discountedPrice}
                      </span>
                    </div>
                  </div>
                  <div className="delivery d-flex gap-2 align-items-center mt-2">
                    <img
                      src="icons/delivery-car.png"
                      alt="delivery_icon"
                      className="delivery_icon m-0"
                    />
                    <p className="delivery_text gilroy m-0">
                      Delivery in 4 to 5 days.
                    </p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>

          {/* Pagination */}
          <ReactPaginate
            previousLabel={
              <FontAwesomeIcon
                icon={faChevronLeft}
                style={{ color: "#001b3d", fontSize: "16px" }}
              />
            }
            nextLabel={
              <FontAwesomeIcon
                icon={faChevronRight}
                style={{ color: "#001b3d", fontSize: "16px" }}
              />
            }
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={Math.ceil(products.length / itemsPerPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            pageClassName="pt-1"
            previousClassName=" pt-1"
            nextClassName=" pt-1"
          />
        </section>
      </div>
    </>
  );
};

export default Categories;
