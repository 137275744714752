import React from "react";
import { Avatar, Button } from "@mui/material";
import PlaceIcon from "@mui/icons-material/Place";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

function Order(props) {
  return (
    <div key={props.id} className="carousel border gilroy mb-5 ">
      <div className="carousel-name-order-id d-flex mx-2 justify-content-between align-items-center">
        <div className="d-flex gap-2 align-items-center ">
          <Avatar src={props.image} />
          <p className="carousel-name m-0">{props.name}</p>
        </div>
        <p className="carousel-order-id m-0">OrderID: {props.orderId}</p>
      </div>
      <div className="carousel-order-information mt-4 mb-3 d-flex gap-2">
        <InfoRoundedIcon className="text-color" fontSize="medium" />
        <p className="carousel-text text-color m-0">{props.information}</p>
      </div>
      <div className="carousel-order-location-date d-flex gap-3">
        <div className="carousel-order-location d-flex gap-2 w-50">
          <PlaceIcon className="text-color" />
          <p className="carousel-text text-color m-0 pe-2">{props.location}</p>
        </div>
        <div className="carousel-order-date d-flex gap-2 ">
          <CalendarMonthIcon className="text-color" />
          <p className="carousel-text text-color m-0">{props.date}</p>
        </div>
      </div>
      <div className="carousel-order-price-status-button d-flex justify-content-between mt-3 mx-3 gilroy">
        <p className="carousel-order-price gilroy">&#8377;{props.price}</p>
        <div className="carousel-order-status-button d-flex gap-2 align-items-center">
          <p className="carousel-order-status m-0">Status: {props.status}</p>
          <Button
            variant="contained"
            size="small"
            style={{ fontSize: "18px" }}
            className="carousel-order-update-button p-1 px-4 text-capitalize"
          >
            Update
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Order;
