import React from "react";
import Navigator from "../Navigator";
import Slider from "./Carousel/Slider";
import { Grid, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import Form from "react-bootstrap/Form";
import { InputGroup } from "react-bootstrap";
import PaginatedItems from "../PaginatedItems";
import { Link } from "react-router-dom";

const SubCategory = () => {
  const data = [{}, {}, {}, {}];
  return (
    <div style={{ marginTop: "72px" }}>
      <Navigator />
      <Slider />

      <Grid
        container
        spacing={2}
        sx={{
          marginTop: {
            lg: "40px",
          },
          marginBottom: {
            lg: "104px",
          },
        }}
        xs={{
          marginTop: {
            lg: "40px",
          },
          marginBottom: {
            lg: "104px",
          },
        }}
      >
        <Grid item xs={12} sm={3}></Grid>

        <Grid item xs={12} sm={6} className="mt-8 mt-lg-0 mx-3 mx-lg-0">
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">
              <SearchIcon sx={{ color: "#6A7D9F" }} />
            </InputGroup.Text>
            <Form.Control
              sx={{ color: "#6A7D9F" }}
              className="gilroy-regular"
              placeholder="Search for a service"
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
            <Button
              variant="contained"
              className="gilroy-regular"
              sx={{ background: "#1365AF" }}
            >
              Search
            </Button>
          </InputGroup>
        </Grid>
        <Grid item xs={12} sm={3}></Grid>
      </Grid>

      <div
        style={{
          margin: "10%",
          display: "flex",
          flexDirection: "column",
          gap: "26px",
        }}
      >
        <div
          style={{
            color: "rgba(0, 0, 0, 1)",
            fontFamily: "Gilroy-SemiBold",
            fontSize: 40,
          }}
        >
          Sub-categories
        </div>

        {data.map((item, index) => {
          return (
            <Link to="/ServiceProviderCatalogue" className="nav-link">
              <div
                key={index}
                style={{
                  border: "1px solid rgba(194, 199, 207, 1)",
                  borderRadius: 4,
                  padding: 18,
                  gap: 10,
                  backgroundColor: "rgba(255, 255, 255, 1)",
                }}
              >
                <div
                  style={{
                    gap: 26,
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{ flexDirection: "row", display: "flex", gap: 26 }}
                  >
                    <div style={{ width: 376, height: 145 }}>
                      <img
                        alt=""
                        style={{ width: "100%", height: "100%" }}
                        src="images/logo/SubCategory.png"
                      />
                    </div>

                    <div
                      style={{
                        gap: 10,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          fontFamily: "Gilroy-SemiBold",
                          fontSize: "28px",
                          color: "rgba(66, 71, 78, 1)",
                        }}
                      >
                        Domestic Help
                      </div>
                      <div
                        style={{
                          fontFamily: "Gilroy-Medium",
                          fontSize: "20px",
                          color: "rgba(250, 155, 0, 1)",
                        }}
                      >
                        45 Service providers
                      </div>
                    </div>
                  </div>

                  <div
                    style={{ transform: "rotate(270deg)", marginRight: "56px" }}
                  >
                    <ExpandCircleDownIcon sx={{ color: "#6A7D9F" }} />
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
      </div>

      <PaginatedItems itemsPerPage={4} />
    </div>
  );
};

export default SubCategory;
