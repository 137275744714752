import React from "react";
import "./OrderServicesPrice.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faClock,
  faMapMarkerAlt,
  faMoneyBill,
  faReceipt,
  faWandMagicSparkles,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "@mui/material";
import { InfoRounded } from "@mui/icons-material";

const OrderServicesPrice = () => {
  return (
    <>
      <section className="order_services__price___container mt-5">
        <div className="order_service_title__area mt-5">
          <h4 className="order_service__title gilroy m-0">
            Pest Control Services
          </h4>
          <p className="order_service_area gilroy">
            General Pest Control, 1 BHK (1000-2000 sq ft.)
          </p>
        </div>
      </section>
      <section className="order_service__price-content mt-4 pt-3">
        <div className="d-flex gap-4 align-items-center">
          <div>
            <div className="order_service__price__content d-flex gap-2 align-items-center">
              <FontAwesomeIcon
                icon={faMoneyBill}
                style={{ color: "2f3033", fontSize: "20px" }}
              />
              <p className="order_service__price_heading service_text gilroy m-0">
                Price
              </p>
            </div>
            <div>
              <p className="order_service__total_price gilroy m-0">Rs 1500/-</p>
            </div>
          </div>

          <div>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#FA9B00",
                fontSize: "20px",
                fontWeight: 500,
                lineHeight: "23.54px",
                color: "#ffffff",
                padding: "10px 20px",
              }}
              className="gilroy"
            >
              Pay Now
            </Button>
          </div>
        </div>
        <div className="order_service__payment_info d-flex gap-3 align-items-center mt-3 p-3">
          <InfoRounded
            sx={{ color: "#1365AF", width: "22px", height: "22px" }}
          />
          <p className="order_service_payment_info__text gilroy-regular m-0">
            You can make the online payment or you can pay cash to the service
            professional after the service.
          </p>
        </div>
      </section>
      <section className="mt-5 d-flex gap-5">
        <div className="order_services__date_and_time pe-3">
          <div className="order_service__date_time d-flex gap-2 align-items-center">
            <FontAwesomeIcon
              icon={faCalendarAlt}
              style={{ color: "2f3033", fontSize: "17px" }}
            />
            <p className="order_service__date_time_heading service_heading__text gilroy m-0">
              Preferred Date & Time:
            </p>
          </div>
          <p className="order_service__date_time_text services_text gilroy-regular">
            24th March, 2024 | 4:00-6 p.m
          </p>
        </div>
        <div className="order_services__duration_time">
          <div className="order_service__duration_time d-flex gap-2 align-items-center">
            <FontAwesomeIcon
              icon={faClock}
              style={{ color: "2f3033", fontSize: "17px" }}
            />
            <p className="order_service__duration_time_heading service_heading__text gilroy m-0">
              Auction Duration:
            </p>
          </div>
          <p className="order_service__duration_time_text services_text gilroy-regular">
            48 hours
          </p>
        </div>
      </section>
      <section className="mt-3 ">
        <div className="order_services__delivery_address">
          <div className="order_service__delivery_address d-flex gap-2 align-items-center">
            <FontAwesomeIcon
              icon={faMapMarkerAlt}
              style={{ color: "2f3033", fontSize: "17px" }}
            />
            <p className="order_service__delivery_address_heading service_heading__text gilroy m-0">
              Service Delivery Address:
            </p>
          </div>
          <p className="order_service__delivery__text services_text gilroy-regular">
            B 72, Arera colony, Bhopal, 462001
          </p>
        </div>
      </section>
      <section className="mt-4 pt-1">
        <div className="order_services__package_include">
          <div className="order_service__package_include d-flex gap-2 align-items-center">
            <FontAwesomeIcon
              icon={faReceipt}
              style={{ color: "2f3033", fontSize: "17px" }}
            />
            <p className="order_service__package_include_heading service_heading__text gilroy m-0">
              Package includes:
            </p>
          </div>
          <ul className="order_service__package_include_list__text services_text gilroy-regular m-0 px-4">
            <li>Cobweb removal</li>
            <li>
              Common Pest Coverage (Ants, Cockroaches, Spider & Mice and Rats)
            </li>
            <li>Seasonal Pest Protection (Wasps, mosquitoes etc.)</li>
          </ul>
        </div>
      </section>
      <section className="mt-4 pt-1">
        <div className="order_services__special_instruction">
          <div className="order_service__special_instruction d-flex gap-2 align-items-center">
            <FontAwesomeIcon
              icon={faWandMagicSparkles}
              style={{ color: "2f3033", fontSize: "17px" }}
            />
            <p className="order_service__special_instruction_heading service_heading__text gilroy m-0">
              Special Instructions:
            </p>
          </div>
          <p className="order_service__special_instruction_list__text services_text text-break gilroy-regular m-0">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores,
            quibusdam saepe porro repellendus sit nam et illo cumque aliquid
            culpa exercitationem ipsa, voluptatem velit vero perferendis tempore
            repellat ad eveniet, in maxime veniam natus. Facere possimus beatae
            eaque! Ut nostrum adipisci tempora eum?
          </p>
        </div>
      </section>
      <div className="d-flex justify-content-center gap-4 mt-4 pt-3">
        <Button
          variant="contained"
          className="gilroy"
          sx={{
            backgroundColor: "#4A7B9D",
            color: "#ffffff",
            fontSize: "20px",
            lineHeight: "20px",
            fontWeight: 600,
            padding: "10px 35px",
            "&:hover": {
              backgroundColor: "#3b6e91",
            },
          }}
        >
          Help
        </Button>
        <Button
          variant="contained"
          className="gilroy"
          sx={{
            backgroundColor: "#E9E7EB",
            color: "#000000",
            fontSize: "20px",
            lineHeight: "20px",
            fontWeight: 600,
            padding: "10px 35px",
            "&:hover": {
              backgroundColor: "#c9c9ca",
            },
          }}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

export default OrderServicesPrice;
