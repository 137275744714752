import React, { useState, useEffect } from "react";
import "./Details.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faWallet,
  faClock,
  faReceipt,
  faWandMagicSparkles,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import Popup from "reactjs-popup";
import { Button } from "@mui/material";
import DegitalSign from "./DegitalSign/DegitalSign";
import { ClearRounded } from "@mui/icons-material";

function Details() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [isBidPopupOpen, setIsBidPopupOpen] = useState(false);

  const handlePlaceBidClick = () => {
    setIsBidPopupOpen(true);
  };

  const handleCloseBidPopup = () => {
    setIsBidPopupOpen(false);
  };

  return (
    <>
      <div className="auction-all-details px-5 d-flex flex-column align-items-center">
        <div className=" px-5">
          <div className=" my-4">
            <h5 className="details-titles title-name fs-4 fw-bold gilroy mb-0">
              Pest Control Services
            </h5>
            <p className="all-details fs-5 fw-bold m-0 p-0">
              General Pest Control, 1 BHK (1000-2000 sq ft.)
            </p>
          </div>
          <div className="my-4">
            <div className=" d-flex gap-2 align-items-center">
              <FontAwesomeIcon
                icon={faWallet}
                style={{ color: "#1365af", fontSize: "20px" }}
              />
              <h5 className="details-titles budget fs-4 fw-bold gilroy mb-0">
                Budget
              </h5>
            </div>
            <p className="all-details fs-5 ">15000 Rupees</p>
          </div>
          <div className="my-4">
            <div className=" d-flex gap-2 align-items-center">
              <FontAwesomeIcon
                icon={faCalendarAlt}
                style={{ color: "#1365af", fontSize: "20px" }}
              />
              <h5 className="details-titles budget fs-4 fw-bold gilroy mb-0">
                Preferred Date & Time:
              </h5>
            </div>
            <p className="all-details fs-5 ">
              24th March, 2024 | 4:00-6:00 p.m
            </p>
          </div>
          <div className="my-4">
            <div className=" d-flex gap-2 align-items-center">
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                style={{ color: "#1365af", fontSize: "20px" }}
              />

              <h5 className="details-titles mb-0 p-0 budget fs-4 fw-bold gilroy ">
                Address:
              </h5>
            </div>
            <p className="all-details fs-5 ">
              B 72, Arera colony, Bhopal, 462001
            </p>
          </div>
          <div className="my-4">
            <div className=" d-flex gap-2 align-items-center">
              <FontAwesomeIcon
                icon={faClock}
                style={{ color: "#1365af", fontSize: "20px" }}
              />

              <h5 className="details-titles auction-duration mb-0 p-0 budget fs-4 fw-bold gilroy ">
                Auction Duration:
              </h5>
            </div>
            <p className="all-details fs-5 ">48 hours</p>
          </div>
          <div className="my-4">
            <div className=" d-flex gap-2 align-items-center">
              <FontAwesomeIcon
                icon={faReceipt}
                style={{ color: "#1365af", fontSize: "20px" }}
              />
              <h5 className="details-titles auction-duration mb-0 p-0 budget fs-4 fw-bold gilroy ">
                Package includes:
              </h5>
            </div>
            <ul className="all-details fs-5 ">
              <li>Cobweb removal</li>
              <li>
                Common Pest Coverage (Ants, Cockroaches, Spider & Mice and Rats)
              </li>
              <li>Seasonal Pest Protection (Wasps, mosquitoes etc.)</li>
            </ul>
          </div>
          <div className="my-4">
            <div className=" d-flex gap-2 align-items-center">
              <FontAwesomeIcon
                icon={faWandMagicSparkles}
                style={{ color: "#1365af", fontSize: "20px" }}
              />
              <h5 className="details-titles auction-duration mb-0 p-0 budget fs-4 fw-bold gilroy ">
                Special Instructions:
              </h5>
            </div>
            <p className="all-details fs-5 ">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam
              beatae autem esse repellendus, possimus, non cupiditate illo
              distinctio omnis quod ipsum. Consequatur, ad! Iste dignissimos
              magni quibusdam iure assumenda.
            </p>
          </div>
        </div>

        <Button
          fullWidth={false}
          style={{
            backgroundColor: "rgba(250, 155, 0, 1)",
            padding: "8px 15px",
          }}
          className="text-capitalize text-white fs-5"
          onClick={handlePlaceBidClick}
        >
          Place a Bid
        </Button>

        <Popup open={isBidPopupOpen} onClose={handleCloseBidPopup} modal nested>
          <div className="popup_content py-4">
            <div className="popup-content mx-4 d-flex align-items-center gap-2">
              <ClearRounded
                sx={{ width: 30, height: 30 }}
                onClick={handleCloseBidPopup}
              />
              <h2 className="digital_sign__heading gilroy-regular m-0">
                Please sign to confirm your bid!
              </h2>
            </div>
            <DegitalSign />
          </div>
        </Popup>
      </div>
    </>
  );
}

export default Details;
