import * as React from "react";
import { Box, Container, Card, Stack, Rating } from "@mui/material";
import "./CustomerSay.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const slides = [
  {
    image: "images/home/explore/profile.svg",
    title: "Sarah J.  ",
    subHeader: "Homeowner",
    description:
      "I had a fantastic experience using this platform to find a contractor for my kitchen remodel. Posting my project was incredibly easy, and within hours, I had multiple bids from qualified professionals. The bidding process allowed me to compare prices and choose the best fit for my budget and vision. The contractor I selected did an amazing job, and the whole experience was smooth and hassle-free. I highly recommend this service!",
    clickEvent: "sliderClick",
    defaultValue: "2",
  },
  {
    image: "images/home/explore/profile.svg",
    title: "Mark T",
    subHeader: "Small Business Owner ",
    description:
      "As a small business owner, finding reliable suppliers can be challenging. This platform made it so much easier for me to get the best deals on office supplies and maintenance services. After posting my request, I received competitive bids from several suppliers. I was able to read reviews, compare offers, and ultimately choose a supplier who met my needs perfectly. This service has saved me time and money, and I will definitely use it again.",
    clickEvent: "sliderClick",
    defaultValue: "3.5",
  },
  {
    image: "images/home/explore/profile.svg",
    title: " Emily R. ",
    subHeader: " Event Planner ",
    description:
      "I used this site to find a caterer for a large corporate event, and I couldn't be happier with the results. The process was straightforward: I posted the details of my event and received several bids from experienced caterers. The ability to compare bids and read reviews from other clients gave me confidence in my decision. The caterer I chose delivered exceptional service, and the event was a huge success. This platform is a game-changer for event planning!",
    clickEvent: "sliderClick",
    defaultValue: "3",
  },
  {
    image: "images/home/explore/profile.svg",
    title: "David L.",
    subHeader: "Home Renovator",
    description:
      "Being able to post my renovation project and receive bids from multiple contractors was a game-changer for me. I was worried about finding a trustworthy and affordable contractor for my bathroom renovation, but this platform made it easy. I got several bids within a day, compared them, and selected a contractor who did an outstanding job. The communication and transparency throughout the process were top-notch. I highly recommend this service to anyone looking for reliable contractors",
    clickEvent: "sliderClick",
    defaultValue: "2.5",
  },
  {
    image: "images/home/explore/profile.svg",
    title: "Jessica K.",
    subHeader: "Home Renovator",
    description:
      "As a freelancer needing various services to run my business, this platform has been invaluable. I've used it to find web developers, graphic designers, and marketing experts. Each time, I received multiple bids from professionals who were eager to help. The rating system and client reviews helped me make informed decisions. The quality of work and the competitive pricing I’ve received have been excellent. This site is a fantastic resource for any business owner or freelancer.",
    clickEvent: "sliderClick",
    defaultValue: "2.5",
  },
];

const CustomerSay = () => {
  // const CustomLeftArrow = ({ onClick }) => (
  //     <button onClick={onClick} className="custom-arrow left-arrow">
  //       Left
  //     </button>
  //   );

  //   const CustomRightArrow = ({ onClick }) => (
  //     <button onClick={onClick} className="custom-arrow right-arrow">
  //       Right
  //     </button>
  //   );

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 576 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 576, min: 0 },
      items: 1,
    },
  };

  const design = (
    <>
      <Container
        maxWidth="lg"
        className="position-relative"
        style={{
          paddingBottom: "80px",
        }}
      >
        <Box
          className="d-flex justify-content-center mb-4 mb-lg-5"
          sx={{
            marginBottom: {
              lg: "46px",
            },
          }}
        >
          <Box
            className="gilroy-regular"
            sx={{
              fontSize: {
                xs: "25px",
                lg: "40px",
              },
              alignItems: "center",
              fontWeight: "600",
            }}
          >
            <span style={{ color: "#1365AF" }}>What </span> Our Customer Say
          </Box>
        </Box>

        <div className="quote">
          <img
            src="images/home/explore/quote.svg"
            style={{ width: "100%" }}
            alt=""
          />
        </div>

        <Carousel
          infinite={true}
          autoPlaySpeed={1000}
          className="pb-5"
          autoPlay={false}
          responsive={responsive}
          showDots={true}
          removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
          dotListClass="custom-dot-list-style"
        >
          {slides.map((item, index) => {
            return (
              <div className="d-flex justify-content-center flex-column align-items-center">
                <Card
                  className="mx-2 mb-2"
                  style={{
                    borderRadius: "4px",
                    border: "1px solid #C2C7CF",
                    background: "#FAF9FD",
                    display: "flex",
                    padding: "50px",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "10px",
                    flexShrink: "0",
                  }}
                >
                  <div className="row">
                    <div className="col-md-12 col-lg-4 d-flex justify-content-center">
                      <img
                        src={item.image}
                        style={{
                          width: "91px",
                          height: "91px",
                        }}
                        alt=""
                      />
                    </div>

                    <div className="col-md-12 col-lg-8 mt-2 mt-lg-0 d-flex flex-column align-items-md-center align-items-lg-start justify-content-center">
                      <p
                        className="p-0 m-0 text-center gilroy-regular"
                        style={{
                          fontSize: "20px",
                          fontStyle: "normal",
                          fontWeight: "500",
                          lineHeight: "normal",
                        }}
                      >
                        {item.title}
                      </p>

                      <p
                        className="p-0 m-0 gilroy-regular"
                        style={{
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "500",
                          lineHeight: "normal",
                        }}
                      >
                        {item.subHeader}
                      </p>
                    </div>

                    <div
                      className="col-md-12"
                      style={{
                        marginTop: "24px",
                        marginBottom: "32px",
                      }}
                    >
                      <p
                        className="description p-0 mb-3 gilroy-regular no-scrollbar"
                        style={{
                          height: "14rem",
                          overflowY: "scroll",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "500",
                          lineHeight: "normal",
                          color: "#001E2F",
                        }}
                      >
                        {item.description}
                      </p>
                    </div>

                    <div className="col-md-12 d-flex justify-content-center">
                      <Stack>
                        <Rating
                          name="half-rating-read"
                          readOnly
                          defaultValue={item.defaultValue}
                          precision={0.5}
                        />
                      </Stack>
                    </div>
                  </div>
                </Card>
              </div>
            );
          })}
        </Carousel>
      </Container>
    </>
  );
  return design;
};

export default CustomerSay;
