import React, { useState } from "react";
import "./QuotesSentOrder.css";
import { Avatar, Button } from "@mui/material";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import { orderData } from "../../Dashboard/RecentOrder/OrderCarousel/orderdata.js";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faMapMarkerAlt,
  faCircle,
  faChevronLeft,
  faChevronRight,
  faCalendarAlt,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
import ReactPaginate from "react-paginate";

const itemsPerPage = 4;

function QuotesSentOrder() {
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const offset = currentPage * itemsPerPage;
  const currentItems = orderData.slice(offset, offset + itemsPerPage);

  return (
    <>
      <div>
        {currentItems.map((item) => (
          <div key={item.id} className="carousel border gilroy mb-4 mt-1 ">
            <div className="carousel-name-order-id d-flex mx-2 justify-content-between align-items-center">
              <div className="d-flex gap-2 align-items-center ">
                <Avatar src={item.image} />
                <p className="customer-name fs-5 fw-bold m-0">{item.name}</p>
                <p
                  style={{
                    fontSize: "15px",
                    color: "rgba(106, 125, 159, 1)",
                    fontWeight: 500,
                  }}
                  className="order-set-time gilroy m-0"
                >
                  <span className="mx-2">
                    {/* <Ellipse color="#6a7d9f" height="8px" width="8px" /> */}
                    <FontAwesomeIcon
                      icon={faCircle}
                      style={{ color: "#6a7d9f", fontSize: "5px" }}
                    />
                  </span>
                  &nbsp;{item.orderSetTime}
                </p>
              </div>

              <MoreVertRoundedIcon />
            </div>
            <div className="d-flex gap-3 mt-2 mx-5 ">
              <Button
                style={{
                  color: "rgba(0, 30, 47, 1)",
                  backgroundColor: "rgba(214, 236, 255, 1)",
                  textTransform: "none",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
                size="small"
              >
                General Pest Control
              </Button>
              <Button
                style={{
                  color: "rgba(0, 30, 47, 1)",
                  backgroundColor: "rgba(214, 236, 255, 1)",
                  textTransform: "none",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
                size="small"
              >
                Pest Control
              </Button>
            </div>
            <div className="d-flex gap-5 mt-3 mb-2 justify-content-start">
              <div className="carousel-order-information d-flex gap-2 ">
                {/* <InformationCircle color="#6a7d9f" height="23px" width="23px" /> */}
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  style={{ color: "#6a7d9f", fontSize: "19px" }}
                />
                <p
                  style={{ fontSize: "18px", fontWeight: 500 }}
                  className="carousel-text text-color m-0 p-0"
                >
                  {item.information}
                </p>
              </div>
              <div className="quotes-order-price d-flex align-items-center ms-1 ps-1 gap-2">
                <CurrencyRupeeIcon sx={{ fontSize: 22, color: "#6a7d9f" }} />
                <p
                  style={{ fontSize: "18px", fontWeight: 500 }}
                  className="carousel-text text-color m-0 p-0"
                >
                  {item.price}
                </p>
              </div>
            </div>
            <div className="carousel-order-location-date d-flex gap-5">
              <div className="carousel-order-location d-flex gap-2 ">
                {/* <Location color="#6a7d9f" height="23px" width="23px" /> */}
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  style={{ color: "#6a7d9f", fontSize: "19px" }}
                />
                <p
                  style={{ fontSize: "18px", fontWeight: 500 }}
                  className="carousel-text text-color m-0 pe-2 py-0"
                >
                  {item.location}
                </p>
              </div>
              <div className="carousel-order-date d-flex gap-2 ">
                {/* <Calendar color="#6a7d9f" height="22px" width="22px" /> */}
                <FontAwesomeIcon
                  icon={faCalendarAlt}
                  style={{ color: "#6a7d9f", fontSize: "19px" }}
                />
                <p
                  style={{ fontSize: "18px", fontWeight: 500 }}
                  className="carousel-text text-color m-0 p-0"
                >
                  {item.date}
                </p>
              </div>
            </div>
            <div className="carousel-order-price-status-button d-flex justify-content-end mt-1 mx-3 gilroy">
              <div className="carousel-order-status-button d-flex gap-2 align-items-center">
                <p className="carousel-order-status m-0 ">
                  Status: {item.status}
                </p>
                <Button
                  variant="contained"
                  size="small"
                  style={{ fontSize: "18px", fontWeight: 500 }}
                  className="carousel-order-update-button gilroy p-1 px-4 text-capitalize"
                >
                  {/* <Chatbubbles color="#ffffff" height="23px" width="23px" /> */}
                  <FontAwesomeIcon
                    icon={faComment}
                    style={{ color: "#ffffff", fontSize: "19px" }}
                  />
                  &nbsp;Chat
                </Button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <ReactPaginate
        previousLabel={
          <FontAwesomeIcon
            icon={faChevronLeft}
            style={{ color: "#6a7d9f", fontSize: "16px" }}
          />
        }
        nextLabel={
          <FontAwesomeIcon
            icon={faChevronRight}
            style={{ color: "#6a7d9f", fontSize: "16px" }}
          />
        }
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={Math.ceil(orderData.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
        pageClassName="pt-1"
        previousClassName=" pt-1"
        nextClassName=" pt-1"
      />
    </>
  );
}

export default QuotesSentOrder;
