import React, { useState, useEffect } from "react";
import "./ServiceCategories.css";
import { ArrowBackRounded } from "@mui/icons-material";
import ServiceCategoriesContent from "./ServiceCategoriesContent/ServiceCategoriesContent";

const ServiceCategories = () => {
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    console.log(`Current active step: ${activeStep}`);
  }, [activeStep]);

  const getHeading = () => {
    const headings = ["Start A Bidding", "Request Details", "Auction Review"];
    return headings[activeStep] || headings[0];
  };

  const renderLinks = () => {
    const links = {
      0: ["Home ", "/ Service categories "],
      1: ["Home ", "/ Pest Control Services ", "/ Request Details "],
      2: [
        "Home ",
        "/ Pest Control Services ",
        "/ Request Details ",
        "/ Reviews",
      ],
    };

    return links[activeStep].map((text, index) => (
      <span
        key={index}
        style={{
          color: index === links[activeStep].length - 1 ? "#1365AF" : "inherit",
        }}
      >
        {text}
      </span>
    ));
  };

  return (
    <>
      <div className="service--categories__container">
        <div className="d-flex gap-3">
          <ArrowBackRounded className="back-arrow d-none d-sm-block mt-2 fs-2 fw-bold" />
          <div className="">
            <h1 className="bid_heading fw-bold gilroy">{getHeading()}</h1>
            <p
              style={{ fontSize: "16px", fontWeight: 600, color: "#6A7D9F" }}
              className="m-0 gilroy"
            >
              {renderLinks()}
            </p>
          </div>
        </div>

        <section>
          <ServiceCategoriesContent
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        </section>
      </div>
    </>
  );
};

export default ServiceCategories;
