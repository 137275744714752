export const services = [
  {
    id: 1,
    title: "Shri Gaurav Pest Control Services",
    checker: "Certifed",
    ratingOutof: "4.0",
    reviews: "100",
    location: "Ibrahimpura, Bhopal, 462001",
    response: "Within an hour",
    openTime: "9 AM to 11 PM",
  },
  {
    id: 2,
    title: "Shri Gaurav Pest Control Services",
    checker: "Certifed",
    ratingOutof: "4.0",
    reviews: "100 reviews",
    location: "Ibrahimpura, Bhopal, 462001",
    response: "Within an hour",
    openTime: "9 AM to 11 PM",
  },
  {
    id: 3,
    title: "Shri Gaurav Pest Control Services",
    checker: "Certifed",
    ratingOutof: "4.0",
    reviews: "100 reviews",
    location: "Ibrahimpura, Bhopal, 462001",
    response: "Within an hour",
    openTime: "9 AM to 11 PM",
  },
  {
    id: 4,
    title: "Shri Gaurav Pest Control Services",
    checker: "Certifed",
    ratingOutof: "4.0",
    reviews: "100 reviews",
    location: "Ibrahimpura, Bhopal, 462001",
    response: "Within an hour",
    openTime: "9 AM to 11 PM",
  },
  {
    id: 5,
    title: "Shri Gaurav Pest Control Services",
    checker: "Certifed",
    ratingOutof: "4.0",
    reviews: "100 reviews",
    location: "Ibrahimpura, Bhopal, 462001",
    response: "Within an hour",
    openTime: "9 AM to 11 PM",
  },
  {
    id: 6,
    title: "Shri Gaurav Pest Control Services",
    checker: "Certifed",
    ratingOutof: "4.0",
    reviews: "100 reviews",
    location: "Ibrahimpura, Bhopal, 462001",
    response: "Within an hour",
    openTime: "9 AM to 11 PM",
  },
  {
    id: 7,
    title: "Shri Gaurav Pest Control Services",
    checker: "Certifed",
    ratingOutof: "4.0",
    reviews: "100 reviews",
    location: "Ibrahimpura, Bhopal, 462001",
    response: "Within an hour",
    openTime: "9 AM to 11 PM",
  },
  {
    id: 8,
    title: "Shri Gaurav Pest Control Services",
    checker: "Certifed",
    ratingOutof: "4.0",
    reviews: "100 reviews",
    location: "Ibrahimpura, Bhopal, 462001",
    response: "Within an hour",
    openTime: "9 AM to 11 PM",
  },
  {
    id: 9,
    title: "Shri Gaurav Pest Control Services",
    checker: "Certifed",
    ratingOutof: "4.0",
    reviews: "100 reviews",
    location: "Ibrahimpura, Bhopal, 462001",
    response: "Within an hour",
    openTime: "9 AM to 11 PM",
  },
  {
    id: 10,
    title: "Shri Gaurav Pest Control Services",
    checker: "Certifed",
    ratingOutof: "4.0",
    reviews: "100 reviews",
    location: "Ibrahimpura, Bhopal, 462001",
    response: "Within an hour",
    openTime: "9 AM to 11 PM",
  },
  {
    id: 11,
    title: "Shri Gaurav Pest Control Services",
    checker: "Certifed",
    ratingOutof: "4.0",
    reviews: "100 reviews",
    location: "Ibrahimpura, Bhopal, 462001",
    response: "Within an hour",
    openTime: "9 AM to 11 PM",
  },
  {
    id: 12,
    title: "Shri Gaurav Pest Control Services",
    checker: "Certifed",
    ratingOutof: "4.0",
    reviews: "100 reviews",
    location: "Ibrahimpura, Bhopal, 462001",
    response: "Within an hour",
    openTime: "9 AM to 11 PM",
  },
  {
    id: 13,
    title: "Shri Gaurav Pest Control Services",
    checker: "Certifed",
    ratingOutof: "4.0",
    reviews: "100 reviews",
    location: "Ibrahimpura, Bhopal, 462001",
    response: "Within an hour",
    openTime: "9 AM to 11 PM",
  },
];
