import React, { useEffect, useState } from "react";
import "./Navbar.css";
import MenuIcon from "@mui/icons-material/Menu";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import CloseIcon from "@mui/icons-material/Close";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Avatar, Button, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import { KeyboardArrowDown } from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";

const StyledAutocomplete = styled(Autocomplete)({
  width: 150,
  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "none",
    border: "none",
    backgroundColor: "#A9C7FF33",
  },
  "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "#B2CDFF",
    border: "1px solid #B2CDFF",
    backgroundColor: "#A9C7FF33",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#B2CDFF",
    border: "1px solid #B2CDFF",
    backgroundColor: "#A9C7FF33",
  },
  "& .MuiInputLabel-root": {
    color: "#ffffff",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#ffffff",
  },
  "& .MuiInputBase-input": {
    color: "#ffffff",
  },
});

const StyledAutocompleteListbox = styled("ul")({
  "& .MuiAutocomplete-option": {
    fontSize: "14px",
    textTransform: "none",
  },
});

const Navbar = () => {
  const navigate = useNavigate();
  const style = {
    position: "absolute",
    top: "52%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40vw",
    bgcolor: "rgba(255, 255, 255, 1)",
    // border: "2px solid #000",
    boxShadow: 24,
    p: 2,
    height: "85vh",
    borderRadius: "4px",
  };

  const handleLogo = () => {
    navigate("/");
  };

  const [mainMenu, setMainMenu] = useState({});
  const [open, setOpen] = useState(false);
  const [adminToken, setAdminToken] = useState(false);
  const [isCity, setIsCity] = useState("");

  // const [logedin, setLogedin] = useState(false);
  // const handleOpen = () => setOpen(true);
  const handleOpen = () => {
    navigate("/login");
  };
  const handleClose = () => setOpen(false);

  // const handleMyAccount = () => {
  //   navigate("/myaccount");
  // };

  const handleCity = (event, newCity) => {
    setIsCity(newCity);
  };

  function show() {
    return setMainMenu({
      display: "flex",
      top: "0",
    });
  }

  function close() {
    return setMainMenu({
      display: "flex",
      top: "-100%",
    });
  }

  // updating navbar when admin is logged in
  useEffect(() => {
    const tokenResponse = localStorage.getItem("loginCredentials");
    if (tokenResponse) {
      const parsedData = JSON.parse(tokenResponse);
      if (parsedData.token) {
        setAdminToken(true);
      } else {
        setAdminToken(false);
      }
    }
  }, []);

  const design = (
    <>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} mx>
            <div
              style={{
                display: "flex",
                // gap: "35px",
                // border: "1px solid red",
                flexDirection: "column",
                justifyContent: "space-around",
                height: "100%",
              }}
            >
              <div
                style={{
                  gap: "24px",
                  // border: "1px solid green",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    // border: "1px solid red",
                    alignItems: "center",
                    flexDirection: "row",
                    display: "flex",
                    gap: 10,
                  }}
                >
                  <CloseIcon
                    onClick={handleClose}
                    fontSize="small"
                    style={{}}
                    color="rgba(26, 27, 30, 1)"
                  />
                  <div
                    style={{
                      // border: "1px solid red",
                      fontFamily: "Gilroy-SemiBold",
                      fontSize: "28px",
                      color: "rgba(19, 101, 175, 1)",
                      display: "flex",
                      gap: 10,
                    }}
                  >
                    Register
                    <div style={{ color: "rgba(0, 0, 0, 1)" }}>
                      to continue!
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    gap: "29px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      color: "rgba(26, 27, 30, 1)",
                      fontFamily: "Gilroy-Medium",
                      fontSize: "16px",
                    }}
                  >
                    First Name
                    <Form.Control
                      sx={{ color: "#6A7D9F" }}
                      className="gilroy-regular"
                      // placeholder="First Name"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                  <div
                    style={{
                      color: "rgba(26, 27, 30, 1)",
                      fontFamily: "Gilroy-Medium",
                      fontSize: "16px",
                    }}
                  >
                    Last Name
                    <Form.Control
                      sx={{ color: "#6A7D9F" }}
                      className="gilroy-regular"
                      // placeholder="First Name"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                  <div
                    style={{
                      color: "rgba(26, 27, 30, 1)",
                      fontFamily: "Gilroy-Medium",
                      fontSize: "16px",
                      gap: "4px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    Mobile Number/Email ID
                    <Form.Control
                      sx={{ color: "#6A7D9F" }}
                      className="gilroy-regular"
                      // placeholder="First Name"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                    <Button
                      sx={{
                        px: {
                          lg: "12px",
                          md: "1px",
                        },
                      }}
                      style={{ alignSelf: "flex-start" }}
                      className="nav-icon-btn2 gilroy"
                    >
                      Get OTP
                    </Button>
                  </div>

                  <div
                    style={{
                      color: "rgba(26, 27, 30, 1)",
                      fontFamily: "Gilroy-Medium",
                      fontSize: "16px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    OTP
                    <Form.Control
                      sx={{ color: "#6A7D9F" }}
                      className="gilroy-regular"
                      // placeholder="First Name"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                    <div
                      style={{
                        fontFamily: "Gilroy-Medium",
                        fontSize: "15px",
                        color: "rgba(128, 128, 127, 1)",
                        display: "flex",
                        alignSelf: "flex-end",
                      }}
                    >
                      Didn’t receive code?{" "}
                      <div style={{ color: "rgba(19, 101, 175, 1)" }}>
                        Resend
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <FormGroup>
                  <FormControlLabel
                    required
                    control={<Checkbox />}
                    label="Remember me"
                    style={{ fontFamily: "Gilroy-Regular" }}
                  />
                  <FormControlLabel
                    required
                    control={<Checkbox />}
                    label="I agree to all the Terms and Privacy policy"
                    style={{ fontFamily: "Gilroy-Regular" }}
                  />
                </FormGroup>
              </div>

              <div
                style={{
                  // border: "1px solid red",
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <Link
                  to="/SubCategory"
                  onClick={handleClose}
                  className="nav-link"
                >
                  <Button
                    sx={{
                      px: {
                        lg: "12px",
                        md: "1px",
                      },
                    }}
                    style={{
                      backgroundColor: "rgba(19, 101, 175, 1)",
                      fontSize: "16px",
                      color: "#ffffff",
                      textTransform: "capitalize",
                      textAlign: "center",
                      fontFamily: "Gilroy-SemiBold",
                      fontStyle: "normal",
                      lineHeight: "20px",
                      paddingLeft: "4px",
                      paddingRight: "4px",
                    }}
                  >
                    Register
                  </Button>
                </Link>

                <Button
                  sx={{
                    px: {
                      lg: "12px",
                      md: "1px",
                    },
                  }}
                  startIcon={
                    <img
                      src="images/logo/google.png"
                      style={{ width: "14px", height: "14px" }}
                      alt=""
                    />
                  }
                  style={{
                    border: " 2px solid rgba(19, 101, 175, 1)",
                    fontSize: "16px",
                    color: "rgba(19, 101, 175, 1)",
                    textTransform: "capitalize",
                    textAlign: "center",
                    fontFamily: "Gilroy-SemiBold",
                    fontStyle: "normal",
                    lineHeight: "20px",
                    paddingLeft: "4px",
                    paddingRight: "4px",
                    width: "252px",
                  }}
                >
                  Sign-in with google
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
      </div>

      <div className="main-box">
        <nav>
          <div className="logo">
            <h1>
              <Avatar
                aria-label="recipe"
                sx={{ width: "48px", height: "48px" }}
                onClick={handleLogo}
              >
                <img
                  alt=""
                  style={{ width: "100%", height: "100%" }}
                  src="images/logo/header-logo.png"
                />
              </Avatar>
            </h1>
          </div>
          <div className="openMenu" onClick={() => show()}>
            <MenuIcon className="open-menu-icon" />
          </div>
          <ul className="mainMenu gap-1 gap-lg-2" style={mainMenu}>
            <li>
              <div>
                <StyledAutocomplete
                  disablePortal
                  clearIcon={null}
                  id="city"
                  options={top100Films}
                  popupIcon={
                    <KeyboardArrowDown
                      sx={{ fontSize: "20px", color: "#B2CDFF" }}
                    />
                  }
                  className="city_name"
                  ListboxComponent={StyledAutocompleteListbox}
                  value={isCity}
                  onChange={handleCity}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="City"
                      size="small"
                      sx={{ fontSize: "12px" }}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocationOnIcon
                              sx={{
                                fontSize: isCity ? "20px" : "25px",
                                color: "#B2CDFF",
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </div>
            </li>

            {adminToken ? (
              <>
                <li className="dashboard dashboard_links nav-link-underline">
                  <Link
                    to={"/dashboard"}
                    style={{ textDecoration: "none" }}
                    className="nav-btn gilroy"
                  >
                    Dashboard
                  </Link>
                </li>

                <li className=" dashboarad dashboard_links nav-link-underline">
                  <Link
                    to="/orders"
                    style={{ textDecoration: "none" }}
                    className="nav-btn gilroy "
                  >
                    Orders
                  </Link>
                </li>

                <li className="dashboard dashboard_links nav-link-underline">
                  <Link
                    to={"/auctions"}
                    style={{ textDecoration: "none" }}
                    className="nav-btn gilroy"
                  >
                    Auctions
                  </Link>
                </li>

                <li className="dashboard dashboard_links nav-link-underline">
                  <Link
                    to={"/chats"}
                    style={{ textDecoration: "none" }}
                    className="nav-btn gilroy "
                  >
                    Chats
                  </Link>
                </li>
                <li className="dashboard dashboard_links nav-link-underline ">
                  <Link
                    to={"/productsAndServices"}
                    style={{ textDecoration: "none" }}
                    className="nav-btn gilroy "
                  >
                    Prducts & Services
                  </Link>
                </li>

                <li className="dashboard_breaker-li"></li>

                <li className="dashboard dashboard_btn ">
                  <Button
                    sx={{
                      px: {
                        lg: "12px",
                        md: "1px",
                      },
                      fontSize: "14px",
                    }}
                    className="nav-btn-icon gilroy p-2 "
                  >
                    Services Catalogue
                  </Button>
                </li>

                <li className="dashboard notification">
                  <IconButton className="nav-btn gilroy">
                    <NotificationsRoundedIcon fontSize="medium" />
                  </IconButton>
                </li>

                <li className="dashboard my_account">
                  <IconButton
                    onClick={() => navigate("/myaccount")}
                    className="dashboard__my_account nav-btn gilroy"
                  >
                    <AccountCircleRoundedIcon fontSize="medium" />
                  </IconButton>
                </li>
              </>
            ) : (
              <>
                <li className="home_link nav-link-underline">
                  <Link
                    to={"/"}
                    style={{ textDecoration: "none" }}
                    className="nav-btn gilroy"
                  >
                    Home
                  </Link>
                </li>
                <li className="auction_link nav-link-underline">
                  <Link
                    to={"/auctions"}
                    style={{ textDecoration: "none" }}
                    className=" nav-btn gilroy"
                  >
                    Auctions
                  </Link>
                </li>
                <li className="about_link nav-link-underline">
                  <Link
                    style={{ textDecoration: "none" }}
                    className=" nav-btn gilroy nav-big-btn"
                  >
                    About
                  </Link>
                </li>
                <li className="breaker-li"></li>
                <li className="start_a_bidding_link">
                  <Button
                    sx={{
                      px: {
                        lg: "12px",
                        md: "1px",
                      },
                      textTransform: "none",
                    }}
                    onClick={() => {
                      navigate("/service_categories");
                    }}
                    startIcon={<AddCircleIcon sx={{ fontSize: "18px" }} />}
                    className="nav-icon-btn2 gilroy "
                  >
                    Start a Bidding
                  </Button>
                </li>
                <li className="signup_link ms-4 ">
                  <Link to="/registration">
                    <Button className="nav-btn gilroy">Sign up</Button>
                  </Link>
                </li>
                <li className="login_link">
                  <Button onClick={handleOpen} className="nav-btn gilroy">
                    Log In
                  </Button>
                </li>
              </>
            )}
            <div className="closeMenu" onClick={() => close()}>
              <CloseIcon className="close-menu-icon " />
            </div>
          </ul>
        </nav>
      </div>
    </>
  );
  return design;
};

export default Navbar;
const top100Films = [
  { label: "Bhopal", year: 1994 },
  { label: "The Godfather", year: 1972 },
  { label: "The Godfather: Part II", year: 1974 },
  { label: "The Dark Knight", year: 2008 },
  { label: "12 Angry Men", year: 1957 },
  { label: "Schindler's List", year: 1993 },
  { label: "Pulp Fiction", year: 1994 },
  {
    label: "The Lord of the Rings: The Return of the King",
    year: 2003,
  },
  { label: "The Good, the Bad and the Ugly", year: 1966 },
  { label: "Fight Club", year: 1999 },
  {
    label: "The Lord of the Rings: The Fellowship of the Ring",
    year: 2001,
  },
  {
    label: "Star Wars: Episode V - The Empire Strikes Back",
    year: 1980,
  },
];
