import React from "react";
import "./HomeAndMaintenance.css";
import Headings from "../Headings/Headings";
import ServiceCarousel from "./ServiceCarousel/ServiceCarousel";
import { Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import SubCategories from "./SubCategories/SubCategories.jsx";

function HomeAndMaintenance() {
  return (
    <>
      <div className="home-and-maintenance__container mx-0">
        <div className="heading-box">
          <Headings headings="Home & Maintenance" />
        </div>
        <ServiceCarousel />
        <div className="sub--categories__container">
          <div className="search--box">
            <div className="input-group mb-3 border rounded">
              <span
                style={{ backgroundColor: "rgb(255,255,255)" }}
                className="input-group-text"
              >
                {/* <Search color="#6a7d9f" height="30px" width="30px" /> */}
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  style={{ color: "#6a7d9f", fontSize: "25px" }}
                />
              </span>
              <input
                type="text"
                className="search--input form-control border border-start-0"
                placeholder="Recipient's username"
                aria-label="Recipient's username"
                aria-describedby="button-addon2"
              />
              <Button
                sx={{
                  backgroundColor: "rgba(19, 101, 175, 1)",
                  color: "rgba(255,255,255,1)",
                  fontSize: "20px",
                  lineHeight: "20px",
                }}
                className="btn btn-outline-secondary gilroy "
                type="button"
                id="button-addon2"
              >
                Search
              </Button>
            </div>
          </div>
          <div className="sub--categories__items my-5">
            <h1 className="sub--categories__heading gilroy ">Sub-categories</h1>
          </div>
          <SubCategories />
        </div>
      </div>
    </>
  );
}

export default HomeAndMaintenance;
