import Carousel from "react-bootstrap/Carousel";
import "./Slider.css";
import { Button } from "@mui/material";

const Slider = () => {
  const design = (
    <>
      <Carousel style={{ marginTop: "0px" }}>
        <Carousel.Item>
          <img
            alt=""
            src="images/home/carousel/a.png"
            className="d-block w-100 "
            style={{ height: "54vh" }}
          />
          <Carousel.Caption>
            <h3>
              <span style={{ color: "#1365AF" }}>Looking for services? </span>
              <br />
              <span style={{ color: "#000000" }}>Well, look no further!</span>
            </h3>

            <Button
              style={{ background: "#1365AF", color: "white" }}
              className="gilroy px-3"
            >
              Start a Bidding
            </Button>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img
            alt=""
            src="images/home/carousel/b.jpg"
            className="d-block w-100"
            style={{ height: "54vh" }}
          />
          <Carousel.Caption>
            <h3>
              <span style={{ color: "#1365AF" }}>Looking for services? </span>
              <br />
              <span style={{ color: "#000000" }}>Well, look no further!</span>
            </h3>

            <Button
              style={{ background: "#1365AF", color: "white" }}
              className="gilroy px-3"
            >
              Start a Bidding
            </Button>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img
            alt=""
            src="images/home/carousel/c.jpg"
            className="d-block w-100"
            style={{ height: "54vh" }}
          />
          <Carousel.Caption>
            <h3>
              <span style={{ color: "#1365AF" }}>Looking for services? </span>{" "}
              <br />
              <span style={{ color: "#000000" }}>Well, look no further!</span>
            </h3>
            <Button
              style={{ background: "#1365AF", color: "white" }}
              className="gilroy px-3"
            >
              Start a Bidding
            </Button>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </>
  );
  return design;
};

export default Slider;
