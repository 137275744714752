import Home from "./Components/Home/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SubCategory from "./Components/Home/SubCategory";
import Navbar from "./Components/Home/Header/Navbar";
import ServiceProviderCatalogue from "./Components/Home/ServiceProviderCatalogue";
import Registration from "./Components/Registration/Registration";
import Login from "./Components/Login/Login";
import Dashboard from "./Components/Dashboard/Dashboard";
import Orders from "./Components/Orders/Orders";
import Auctions from "./Components/Auctions/Auctions";
import AuctionDetails from "./Components/Auctions/AuctionDetails/AuctionDetails";
import MyAccount from "./Components/SellerAccount/MyAccount";
import ChatsBoxContainer from "./Components/Chats/ChatsBoxContainer";
import HomeAndMaintenance from "./Components/HomeAndMaintenance/HomeAndMaintenance";
import ServicesProvided from "./Components/HomeAndMaintenance/ProvidedServices/ServicesProvided";
import ServiceCategories from "./Components/ServicesCategories/ServiceCategories";
import SetupProfile from "./Components/ProfileSetup/SetupProfile";
import SellerProfile from "./Components/SellerProfile/SellerProfile";
import Footer from "./Components/Footer/Footer";
import MyAuctions from "./Components/MyAuctions/MyAuctions";
import MyAuctionsDetails from "./Components/MyAuctions/MyAuctionsDetails/MyAuctionsDetails";
import BidderProfile from "./Components/MyAuctions/BidderProfile/BidderProfile";
import MyOrders from "./Components/MyOrders/MyOrders";
import OrderPage from "./Components/MyOrders/MyOrderPage/OrderPage";
import BuyersAccounts from "./Components/BuyersAccounts/BuyersAccounts";
import CustNotifications from "./Components/BuyersNotifications/CustNotifications";
import ProductsAndServices from "./Components/ProductsAndServices/ProductsAndServices";
import AddProducts from "./Components/ProductsAndServices/MyProducts/AddProducts/AddProducts";
import AddServices from "./Components/ProductsAndServices/MyServices/AddServices/AddServices";
import AddServiceDetails from "./Components/ProductsAndServices/MyServices/AddServiceDetails/AddServiceDetails";

const App = () => {
  const design = (
    <>
      <Router>
        <Navbar />

        <Routes>
          <Route exact path="/" element={<Home />} />
          {/* <Route exact path="/" element={<Test />} /> */}
          {/* <Route path="*" element={<Notfound />} /> */}
          {/* Kritik */}

          <Route exact path="/SubCategory" element={<SubCategory />} />
          <Route
            exact
            path="/ServiceProviderCatalogue"
            element={<ServiceProviderCatalogue />}
          />
          <Route exact path="/dashboard" element={<Dashboard />} />

          {/* Registration Process For Seller */}
          <Route exact path="/registration" element={<Registration />} />
          <Route exact path="/seller-profile" element={<SellerProfile />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/profile_setup" element={<SetupProfile />} />
          <Route
            exact
            path="/productsAndServices"
            element={<ProductsAndServices />}
          />
          <Route exact path="/add_products" element={<AddProducts />} />
          <Route exact path="/add_services" element={<AddServices />} />
          <Route
            exact
            path="/add_services_details"
            element={<AddServiceDetails />}
          />

          {/* For Buyers */}
          <Route exact path="/home_services" element={<HomeAndMaintenance />} />
          <Route
            exact
            path="/services_provided"
            element={<ServicesProvided />}
          />
          <Route
            exact
            path="/service_categories"
            element={<ServiceCategories />}
          />
          <Route exact path="/my_auctions" element={<MyAuctions />} />
          <Route
            exact
            path="/my_auction__details"
            element={<MyAuctionsDetails />}
          />
          <Route exact path="/service_provider" element={<BidderProfile />} />
          <Route exact path="/my_orders" element={<MyOrders />} />
          <Route exact path="/my_order_page" element={<OrderPage />} />
          <Route exact path="/cust_accounts" element={<BuyersAccounts />} />
          <Route
            exact
            path="/cust_notifications"
            element={<CustNotifications />}
          />

          <Route exact path="/orders" element={<Orders />} />
          <Route exact path="/auctions" element={<Auctions />} />
          <Route exact path="/auctionDetails" element={<AuctionDetails />} />
          <Route exact path="/myaccount" element={<MyAccount />} />
          <Route exact path="/chats" element={<ChatsBoxContainer />} />
        </Routes>
        {/* Kritik */}
        <Footer />
      </Router>
    </>
  );
  return design;
};

export default App;
