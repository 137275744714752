import React, { useState, useEffect } from "react";
import "./Auctions.css";
import TwoPageButton from "./TwoPageButton/TwoPageButton";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import FilterAuctions from "./FilterAuctions";
import { Box, useMediaQuery } from "@mui/material";
import OnGoing from "./AuctionsContainer/OnGoingContainer/OnGoing.jsx";
import BiddedOn from "./AuctionsContainer/BiddedOnContainer/BiddedOn.jsx";
import Headings from "../Headings/Headings.jsx";
import SortingAuctions from "./SortingAuctions.jsx";

function Auctions() {
  const [searchText, setSearchText] = useState("");
  const [currentView, setCurrentView] = useState("ongoing");

  // Screen Size responsive-----------
  const isSmallScreen = useMediaQuery("(max-widt: 576px) ");

  useEffect(() => {
    console.log(searchText);
  }, [searchText]);

  const handleInputChange = (event) => {
    setSearchText(event.target.value);
  };

  return (
    <div className="auctions-box">
      <Headings headings="Auctions" />
      <div className="auctions-contents-and-details">
        <div className="auctions-two-pages">
          <TwoPageButton
            currentView={currentView}
            setCurrentView={setCurrentView}
          />
          <div className="d-flex justify-content-between mt-5">
            <div className="d-flex w-auto flex-wrap gap-2">
              <FilterAuctions />
            </div>
            <div className="d-flex align-items-center gap-2">
              <SortingAuctions />
            </div>
          </div>
        </div>
        <div className="mt-0 mx-4 p-5">
          <div className="search_bar search-bar border px-1 d-flex align-items-center justify-content-center">
            <SearchRoundedIcon style={{ color: "rgba(106, 125, 159, 1)" }} />
            <Box sx={{ m: 0, width: "100%" }}>
              <input
                className="w-100 fs-6 fw-medium"
                type="text"
                id="searchForService"
                placeholder="Search For Service"
                value={searchText}
                onChange={handleInputChange}
              />
            </Box>
          </div>
        </div>
        <div className="auction-container px-0 px-sm-3 ">
          {currentView === "ongoing" && <OnGoing />}
          {currentView === "biddedOn" && <BiddedOn />}
        </div>
      </div>
    </div>
  );
}

export default Auctions;
