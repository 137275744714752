import React, { useState } from "react";
import "./PickService.css";
import { TextField, MenuItem, Select } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const PickService = () => {
  return (
    <>
      <div className="search_pick__service mt-4 mb-5  w-100">
        <div className="d-flex align-items-center border px-3 m-5 mt-0 ">
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            style={{ color: "#6a7d9f", fontSize: "20px" }}
          />
          <TextField
            sx={{
              border: "none",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                },
              },
            }}
            fullWidth
            size="small"
          />
        </div>
        <div className="w-100">
          <h3 className="heading_service gilroy my-3">Home & Maintenance</h3>
          <Row>
            <Col item xs={12} sm={6} md={12} lg={6} className="p-1">
              <Select
                value=""
                onChange={() => {}}
                fullWidth
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{ background: "#EEEEF4" }}
              >
                <MenuItem value="" disabled>
                  Open this select menu
                </MenuItem>
                <MenuItem value={1}>One</MenuItem>
                <MenuItem value={2}>Two</MenuItem>
                <MenuItem value={3}>Three</MenuItem>
              </Select>
            </Col>
            <Col item xs={12} sm={6} md={12} lg={6} className="p-1">
              <Select
                value=""
                onChange={() => {}}
                fullWidth
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{ background: "#EEEEF4" }}
              >
                <MenuItem value="" disabled>
                  Open this select menu
                </MenuItem>
                <MenuItem value={1}>One</MenuItem>
                <MenuItem value={2}>Two</MenuItem>
                <MenuItem value={3}>Three</MenuItem>
              </Select>
            </Col>
            <Col item xs={12} sm={6} md={12} lg={6} className="p-1">
              <Select
                value=""
                onChange={() => {}}
                fullWidth
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{ background: "#EEEEF4" }}
              >
                <MenuItem value="" disabled>
                  Open this select menu
                </MenuItem>
                <MenuItem value={1}>One</MenuItem>
                <MenuItem value={2}>Two</MenuItem>
                <MenuItem value={3}>Three</MenuItem>
              </Select>
            </Col>
            <Col item xs={12} sm={6} md={12} lg={6} className="p-1">
              <Select
                value=""
                onChange={() => {}}
                fullWidth
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{ background: "#EEEEF4" }}
              >
                <MenuItem value="" disabled>
                  Open this select menu
                </MenuItem>
                <MenuItem value={1}>One</MenuItem>
                <MenuItem value={2}>Two</MenuItem>
                <MenuItem value={3}>Three</MenuItem>
              </Select>
            </Col>
            <Col item xs={12} sm={6} md={12} lg={6} className="p-1">
              <Select
                value=""
                onChange={() => {}}
                fullWidth
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{ background: "#EEEEF4" }}
              >
                <MenuItem value="" disabled>
                  Open this select menu
                </MenuItem>
                <MenuItem value={1}>One</MenuItem>
                <MenuItem value={2}>Two</MenuItem>
                <MenuItem value={3}>Three</MenuItem>
              </Select>
            </Col>
            <Col item xs={12} sm={6} md={12} lg={6} className="p-1">
              <Select
                value=""
                onChange={() => {}}
                fullWidth
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{ background: "#EEEEF4" }}
              >
                <MenuItem value="" disabled>
                  Open this select menu
                </MenuItem>
                <MenuItem value={1}>One</MenuItem>
                <MenuItem value={2}>Two</MenuItem>
                <MenuItem value={3}>Three</MenuItem>
              </Select>
            </Col>
            <Col item xs={12} sm={6} md={12} lg={6} className="p-1">
              <Select
                value=""
                onChange={() => {}}
                fullWidth
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{ background: "#EEEEF4" }}
              >
                <MenuItem value="" disabled>
                  Open this select menu
                </MenuItem>
                <MenuItem value={1}>One</MenuItem>
                <MenuItem value={2}>Two</MenuItem>
                <MenuItem value={3}>Three</MenuItem>
              </Select>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default PickService;
