import React from "react";
import "./TwoPageButton.css";
import { Button } from "@mui/material";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";

function TwoPageButton({ currentView, setCurrentView }) {
  // Screen size for responsiveness----------

  return (
    <div className="ongoing-button--biddedon-button d-flex justify-content-center mt-5">
      <Button
        varient="contained"
        sx={{
          backgroundColor:
            currentView === "ongoing"
              ? "rgba(214, 236, 255, 1)"
              : "transparent",
          color: "rgba(0, 30, 47, 1)",
        }}
        className="ongoing_button__styles
        } border border-end-0 border-secondary"
        onClick={() => setCurrentView("ongoing")}
      >
        {currentView === "ongoing" ? (
          <DoneRoundedIcon sx={{ fontSize: "16px" }} />
        ) : (
          ""
        )}
        On going
      </Button>
      <Button
        varient="contained"
        sx={{
          backgroundColor:
            currentView === "biddedOn"
              ? "rgba(214, 236, 255, 1)"
              : "transparent",
          color: "rgba(0, 30, 47, 1)",
        }}
        className="bidden_button__style border border-secondary"
        onClick={() => setCurrentView("biddedOn")}
      >
        {currentView === "biddedOn" ? (
          <DoneRoundedIcon sx={{ fontSize: "16px" }} />
        ) : (
          ""
        )}
        Bidded on
      </Button>
    </div>
  );
}

export default TwoPageButton;
