import React, { useState } from "react";
import "./BiddedOn.css";
import "../OnGoingContainer/OnGoing.css";
import auctionImage from "../../../Rectangle 2057.png";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Avatar from "@mui/material/Avatar";
import { Box, Button } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { auctionContent } from "../../auctionsdata";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faChevronLeft,
  faChevronRight,
  faComments,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";

const itemsPerPage = 4;

function BiddedOn() {
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const offset = currentPage * itemsPerPage;
  const currentItems = auctionContent.slice(offset, offset + itemsPerPage);

  return (
    <>
      <div>
        {currentItems &&
          currentItems.map((item) => (
            <div className="auction-content border rounded p-4 gilroy my-3">
              <div className="d-flex justify-content-center align-items-center ">
                <img
                  src={auctionImage}
                  alt={item.title}
                  style={{
                    width: "10rem",
                    height: "10rem",
                    borderRadius: "none",
                  }}
                />
                <div className="details p-0 mx-2 w-100 ">
                  <div className=" w-100 d-flex pt-2 justify-content-between align-items-center ">
                    <div className="title-container w-100">
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <div className="d-flex align-items-center">
                          <h4 className="content-title mx-3 gilroy">
                            {item.title}
                          </h4>
                          <span className="bids-status text-uppercase">
                            Open
                          </span>
                        </div>
                        <div className="d-flex">
                          <span
                            className="text-lowercase mt-1"
                            style={{
                              color: "rgba(106, 125, 159, 1)",
                              fontSize: "18px",
                            }}
                          >
                            {item.orderTime}
                          </span>
                          <MoreVertIcon
                            style={{ fontSize: "35px", color: "#000" }}
                          />
                        </div>
                      </div>

                      <div className="d-flex gap-3 mt-2 mx-2">
                        <p
                          style={{
                            color: "rgba(0, 30, 47, 1)",
                            backgroundColor: "rgba(214, 236, 255, 1)",
                            textTransform: "none",
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                          size="small"
                          className="general-pest-control--btn py-1 px-2 rounded gilroy "
                        >
                          General Pest Control
                        </p>
                        <p
                          style={{
                            color: "rgba(0, 30, 47, 1)",
                            backgroundColor: "rgba(214, 236, 255, 1)",
                            textTransform: "none",
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                          size="small"
                          className="pest-control--btn py-1 px-2 rounded gilroy"
                        >
                          Pest Control
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between ">
                    <div
                      style={{ width: "50rem" }}
                      className="bids-info d-flex w-100 "
                    >
                      <div className="d-flex flex-column gap-3 justify-content-between align-items-start my-3 mx-2  ">
                        <div className="avatar--location d-flex gap-2 align-items-center">
                          <Avatar
                            sx={{ width: 24, height: 24 }}
                            src={item.userImage}
                          />
                          <span className=" details gilroy ">
                            {item.username}
                          </span>
                        </div>
                        <div className="d-flex gap-2 align-items-center">
                          <InfoIcon
                            className="icons-color"
                            sx={{ width: 25, height: 25 }}
                          />
                          <p className="details gilroy m-0 p-0 ">
                            {item.serviceOrdered}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex flex-column gap-3 justify-content-between align-items-start my-3 mx-2   ">
                        <div className="d-flex gap-2 justify-content-start align-items-center ">
                          <FontAwesomeIcon
                            icon={faMapMarkerAlt}
                            style={{ color: "#6a7d9f", fontSize: "20px" }}
                          />
                          <p className="details gilroy m-0 p-0 ">
                            {item.location}
                          </p>
                        </div>
                        <div className="d-flex gap-2 justify-content-start align-items-center ">
                          {/* <Calendar
                            color={"#6a7d9f"}
                            height="23px"
                            width="23px"
                          /> */}
                          <FontAwesomeIcon
                            icon={faCalendarAlt}
                            style={{ color: "#6a7d9f", fontSize: "20px" }}
                          />
                          <p className="details gilroy m-0 p-0 ">
                            {item.dateTime}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className=" px-2 w-25 d-flex flex-column align-items-end">
                      <p className="last-bid-amount m-0 p-0">&#x20B9;1700/-</p>
                      <p className="sub-content m-0 p-0">Your last bid</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center gap-3 mt-4">
                  <div className="d-flex">
                    {/* ----------------***************--------------- */}
                    <div className="circle ">
                      <Avatar sx={{ width: 27, height: 27 }} />
                    </div>
                    <div className="circle circle-margin ">
                      <Avatar sx={{ width: 27, height: 27 }} />
                    </div>
                    <div className="circle circle-margin ">
                      <Avatar sx={{ width: 27, height: 27 }} />
                    </div>
                    {/* ----------------***************--------------- */}
                  </div>
                  <div className="d-flex gap-2">
                    <div className="bids-box border px-3  rounded-pill">
                      <p className="bids m-0 p-0">
                        <span className="fw-bold">{item.statusBids}</span> Bids
                      </p>
                    </div>
                    <div className="timer border px-3 rounded-pill">
                      <p className="hours-left m-0 p-0">{item.status}</p>
                    </div>
                  </div>
                </div>
                <div className="d-flex gap-3 mt-2 align-items-center">
                  <Box sx={{ "& button": { m: 1 } }}>
                    <Button
                      style={{
                        backgroundColor: "rgba(19, 101, 175, 1)",
                        color: "rgba(255,255,255,1)",
                        padding: "2px 25px",
                        fontSize: "18px",
                      }}
                      size="small"
                      className="place-bid-button d-flex flex-row align-items-center text-capitalize"
                    >
                      <FontAwesomeIcon
                        icon={faComments}
                        style={{ color: "#ffffff", fontSize: "20px" }}
                      />
                      <span>Chat</span>
                    </Button>
                  </Box>
                </div>
              </div>
            </div>
          ))}
      </div>
      <ReactPaginate
        previousLabel={
          // <ChevronBack color="#001b3d" width="18px" height="18px" />
          <FontAwesomeIcon
            icon={faChevronLeft}
            style={{ color: "#001b3d", fontSize: "16px" }}
          />
        }
        nextLabel={
          // <ChevronForward color="#001b3d" width="18px" height="18px" />
          <FontAwesomeIcon
            icon={faChevronRight}
            style={{ color: "#001b3d", fontSize: "16px" }}
          />
        }
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={Math.ceil(auctionContent.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
        pageClassName="pt-1"
        previousClassName=" pt-1"
        nextClassName=" pt-1"
      />
    </>
  );
}

export default BiddedOn;
