import { Avatar, Button } from "@mui/material";
import React, { useState } from "react";
import "./ReviewsBox.css";
import { reviewsContent } from "./reviewBoxContents";
import ReactPaginate from "react-paginate";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const itemsPerPage = 3;

function ReviewsBox() {
  const [currentPage, setCurrentPage] = useState(0);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const offset = currentPage * itemsPerPage;
  const currentItems = reviewsContent.slice(offset, offset + itemsPerPage);

  return (
    <>
      <div className="reviews-container ">
        {currentItems &&
          currentItems.map((item) => (
            <div key={item.id} className=" review-item  ">
              <div className="reviews-box">
                <div className="d-flex align-items-center gap-3">
                  <div>
                    <Avatar
                      alt="Remy Sharp"
                      src={item.avatar}
                      sx={{ width: 48, height: 48 }}
                    />
                  </div>
                  <div className="my-1 p-0 d-flex flex-column justify-content-center">
                    <p
                      style={{
                        fontSize: "20px",
                        color: "rgba(0,0,0,1)",
                        fontWeight: 600,
                      }}
                      className="m-0 p-0 gilroy"
                    >
                      {item.name}
                    </p>
                    <p
                      style={{
                        fontSize: "16px",
                        color: "#6a7d9f",
                        fontWeight: 500,
                      }}
                      className="m-0 p-0 "
                    >
                      <FontAwesomeIcon
                        icon={faMapMarkerAlt}
                        style={{ color: "#6a79df", fontSize: "18px" }}
                      />
                      <span className="gilroy">{item.location}</span>
                    </p>
                  </div>
                </div>
                <Stack spacing={1}>
                  <Rating
                    name="half-rating-read"
                    defaultValue={item.rating}
                    precision={0.5}
                    size="small"
                    readOnly
                  />
                </Stack>
                <div className="customer-reviews-comments mt-3">
                  <p>{item.feedback}</p>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <p className="date-of-feedback">{item.date}</p>
                  <Button
                    style={{
                      backgroundColor: "rgba(74, 123, 157, 1)",
                      padding: "3px 15px",
                      color: "rgba(255,255,255,1)",
                    }}
                    size="small"
                  >
                    Reply
                  </Button>
                </div>
              </div>
            </div>
          ))}
      </div>
      <ReactPaginate
        previousLabel={
          <FontAwesomeIcon
            icon={faChevronLeft}
            style={{ color: "#001b3d", fontSize: "16px" }}
          />
        }
        nextLabel={
          <FontAwesomeIcon
            icon={faChevronRight}
            style={{ color: "#001b3d", fontSize: "16px" }}
          />
        }
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={Math.ceil(reviewsContent.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
        pageClassName="pt-1"
        previousClassName=" pt-1"
        nextClassName=" pt-1"
      />
    </>
  );
}

export default ReviewsBox;
