import React, { useState, useEffect } from "react";
import Carousel from "./Carousel/Carousel";
import Popular from "./Services/Popular/Popular";
import PropertyRent from "./Services/PropertyRent/PropertyRent";
import Business from "./Services/Business/Business";
import Automobile from "./Services/Automobile/Automobile";
import Explore from "./Explore/Explore";
import CustomerSay from "./CustomerSay/CustomerSay";
import Counter from "./Counter/Counter";
import Newslater from "./Newslater/Newslater";
import Download from "./Download/Download";
import HealthWellness from "./Services/HealthWellness/HealthWellness";
import { useMediaQuery } from "@mui/material";
import axios from "axios";

const Home = () => {
  const [homeSlider, setHomeSlider] = useState({});
  // const [popularSlider , setPopularSlider] = useState({})
  const homeSliderApi = "http://65.1.109.1/api/home";

  const isVerySmallScreen = useMediaQuery("(max-width: 568px) ");
  // const isSmallScreen = useMediaQuery("(max-width: 568px) ");

  useEffect(() => {
    const fetchHomeSliderApi = async () => {
      try {
        const response = await axios.get(homeSliderApi);
        if (response.status === 200) {
          const sliderContent = response.data.data;

          // console.log("slider", response);

          setHomeSlider(sliderContent);
          // console.log("sliderContent", sliderContent.category);
        }
      } catch (error) {
        console.log("error in api ", error);
      }
    };
    fetchHomeSliderApi();
  }, []);

  const design = (
    <>
      <Carousel data={homeSlider} />
      <div
        style={{
          width: "100%",
          padding: isVerySmallScreen ? "0 2rem" : "0 6rem",
        }}
      >
        <Popular data={homeSlider} />

        <Business />
        <Automobile />
        <PropertyRent />
        <HealthWellness />

        <Explore />
        <CustomerSay />
        <Counter />
      </div>
      <Newslater />
      <Download />
    </>
  );
  return design;
};

export default Home;
