import React from "react";
import "./Reviews.css";
import { Button } from "@mui/material";

const Reviews = () => {
  const label = { fontSize: "20px", fontWeight: 600, color: "#4A7B9D" };
  const labelTitle = {
    fontSize: "16px",
    fontWeight: 500,
    color: "#6A7D9F",
    lineHeight: "18.83px",
  };
  return (
    <>
      <div className="w-100 position-relative mb-5">
        <h4
          style={{
            color: "#343538",
            fontSize: "24px",
            fontWeight: 600,
            lineHeight: "28.63px",
          }}
          className="gilroy my-5"
        >
          Your Request
        </h4>
        <div className="reviews_container border rounded mt-1 p-4">
          <section>
            <p style={label} className="gilroy m-0">
              Pest Control Services
            </p>
            <p
              style={{ fontSize: "16px", fontWeight: 500, color: "#2F3033" }}
              className="gilroy m-0"
            >
              General Pest Control, 1 BHK (1000-2000 sq ft.)
            </p>
          </section>
          <section className="d-flex gap-5 mt-4">
            <div>
              <p style={labelTitle} className="gilroy m-0">
                Budget
              </p>
              <p style={label} className="gilroy m-0">
                Pest Control Services
              </p>
            </div>
            <div>
              <p style={labelTitle} className="gilroy m-0">
                Preferred Date & Time
              </p>
              <p style={label} className="gilroy m-0">
                24th March, 2024 | 4:00-6 p.m
              </p>
            </div>
          </section>
          <section className="mt-4">
            <p style={labelTitle} className="gilroy m-0">
              Address
            </p>
            <p style={label} className="gilroy m-0">
              B 72, Arera colony, Bhopal, 462001
            </p>
          </section>
          <section className="mt-4">
            <p style={labelTitle} className="gilroy m-0">
              Auction Duration
            </p>
            <p style={label} className="gilroy m-0">
              48 hours
            </p>
          </section>
          <section className="mt-4">
            <p style={labelTitle} className="gilroy m-0">
              Package includes:
            </p>
            <ul
              style={{ color: "#2F3033", fontSize: "14px", fontWeight: 400 }}
              classame="gilroy"
            >
              <li>Cobweb removal</li>
              <li>
                Common Pest Coverage (Ants, Cockroaches, Spider & Mice and Rats)
              </li>
              <li>Seasonal Pest Protection (Wasps, mosquitoes etc.)</li>
            </ul>
          </section>
          <section className="mt-4">
            <p style={labelTitle} className="gilroy m-0">
              Special Instructions*
            </p>
            <p
              style={{ color: "#2F3033", fontSize: "14px", fontWeight: 400 }}
              classame="gilroy m-0"
            >
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis,
              Lorem ipsum dolor sit amet consectetur adipisicing
            </p>
          </section>
          <div
            style={{ bottom: 0, right: 0 }}
            className=" position-absolute pb-4 px-4"
          >
            <Button
              sx={{
                color: "#001E2F",
                backgroundColor: "#D6ECFF",
                textTransform: "none",
                fontSize: "14px",
                fontWeight: "500",
                padding: "0 0",
              }}
              className="gilroy"
            >
              Read more
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reviews;
