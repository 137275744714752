import { Box, Button, Container, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./PropertyRent.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const slides = [
  {
    image: "images/home/services/home/1.png",
    title: "Domestic Help",
    description: "1234 Service providers",
    clickEvent: "sliderClick",
  },
  {
    image: "images/home/services/home/2.png",
    title: "Pest Control services",
    description: "1234 Service providers",
    clickEvent: "sliderClick",
  },
  {
    image: "images/home/services/home/3.png",
    title: "Packers and Movers",
    description: "1234 Service providers",
    clickEvent: "sliderClick",
  },
  {
    image: "images/home/services/home/4.png",
    title: "Plumbers",
    description: "1234 Service providers",
    clickEvent: "sliderClick",
  },
];

const PropertyRent = () => {
  const navigate = useNavigate();

  const isSmallScreen = useMediaQuery("(max-width: 568px) ");

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const design = (
    <>
      <Container
        maxWidth="lg"
        style={{
          borderBottom: "1px solid #E0E3E7",
          marginBottom: "80px",
          paddingBottom: "80px",
        }}
        className="property"
      >
        <Box
          className="d-flex justify-content-center mb-4 mb-lg-5"
          sx={{
            marginBottom: {
              lg: "46px",
            },
          }}
        >
          <Box
            className="gilroy-regular"
            sx={{
              fontSize: {
                xs: "25px",
                lg: "40px",
              },
              alignItems: "center",
              fontWeight: "600",
            }}
          >
            <span style={{ color: "#1365AF" }}>Property</span>&nbsp;and Rent
          </Box>
        </Box>
        <div className="property_rent__carousel">
          <Carousel
            infinite={true}
            autoPlaySpeed={2000}
            autoPlay={true}
            responsive={responsive}
            removeArrowOnDeviceType={["mobile", "tablet"]}
          >
            {slides.map((item, index) => {
              return (
                <div
                  className="d-flex justify-content-center flex-column align-items-center"
                  onClick={() => {
                    navigate("/home_services");
                  }}
                >
                  <img
                    src={item.image}
                    alt={item.title}
                    width="230px"
                    height="180px"
                    className="px-2"
                  />

                  <p
                    className="pb-1 px-0 pt-0 mx-0 mb-0 gilroy-regular"
                    style={{
                      marginTop: "19px",
                      color: "#000",
                      textAlign: "center",
                      fontSize: "20px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "normal",
                    }}
                  >
                    {item.title}
                  </p>

                  <p
                    className="p-0 m-0 gilroy-regular"
                    style={{
                      color: " #42474E",
                      textAlign: "center",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "normal",
                    }}
                  >
                    {item.description}
                  </p>
                </div>
              );
            })}
          </Carousel>
        </div>
      </Container>
    </>
  );
  return design;
};

export default PropertyRent;
