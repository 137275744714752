import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { AddBoxRounded } from "@mui/icons-material";
import CategoriesButtons from "./CategoriesButton/CategoriesButtons";
import Categories from "./Categories/Categories";
import VirticalStyleLine from "../../VirticalStyleLine/VirticalStyleLine";

const MyProducts = () => {
  const navigate = useNavigate();
  const handleAddProduct = () => {
    navigate("/add_products");
  };
  return (
    <>
      <div className="my_products__container mt-4">
        <div className="add_my_product__button">
          <Button
            sx={{
              fontSize: "20px",
              color: "#1365AF",
              lineHeight: "28.8px",
              fontWeight: 400,
              backgroundColor: "#ECF0FF",
            }}
            onClick={handleAddProduct}
            className="gilroy py-3 px-5"
          >
            <AddBoxRounded />
            &nbsp;&nbsp;Add More Products
          </Button>
        </div>
        <div className="d-flex justify-content-around gap-4 mt-5">
          <div md={4} className="d-none d-md-block">
            <CategoriesButtons />
          </div>
          <VirticalStyleLine />
          <div md={8}>
            <Categories />
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProducts;
