import Carousel from "react-bootstrap/Carousel";
import "./ServiceCarousel.css";

const ServiceCarousel = () => {
  const design = (
    <>
      <Carousel className="mt-4 w-100 px-0 mx-0 ">
        <Carousel.Item>
          <img
            alt=""
            src="images/home/carousel/a.png"
            className="d-block w-100 "
            style={{ height: "54vh" }}
          />
          <Carousel.Caption>
            <h1>
              <span style={{ color: "#fff" }}>Revamp your</span>
              <br />
              <span style={{ color: "#fff" }}>HOME INTERIOR</span>
            </h1>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img
            alt=""
            src="images/homeService/1.png"
            className="d-block w-100"
            style={{ height: "54vh" }}
          />
          <Carousel.Caption>
            <h1>
              <span style={{ color: "#fff" }}>Revamp your</span>
              <br />
              <span style={{ color: "#fff" }}>HOME INTERIOR</span>
            </h1>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img
            alt=""
            src="images/home/carousel/c.jpg"
            className="d-block w-100"
            style={{ height: "54vh" }}
          />
          <Carousel.Caption>
            <h1>
              <span style={{ color: "#fff" }}>Revamp your</span>
              <br />
              <span style={{ color: "#fff" }}>HOME INTERIOR</span>
            </h1>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </>
  );
  return design;
};

export default ServiceCarousel;
