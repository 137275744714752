import React, { useState } from "react";
import "./CategoriesButtons.css";
import { Button, useMediaQuery } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBug } from "@fortawesome/free-solid-svg-icons";

const CategoriesButtons = () => {
  const [currentBtn, setCurrentBtn] = useState(1);

  // Style for the active button
  const activeButtonStyle = {
    width: "16.6em",
    fontSize: "18px",
    fontWeight: 500,
    color: "#42474E",
    justifyContent: "start",
    backgroundColor: "#D6ECFF",
    "&:hover": {
      backgroundColor: "#D6ECFF",
    },
    border: "1px solid #1365AF",
  };

  // Style for the inactive button
  const inactiveButtonStyle = {
    width: "100%",
    fontSize: "18px",
    fontWeight: 500,
    color: "#42474E",
    justifyContent: "start",
    backgroundColor: "#FAF9FD",
    border: "1px solid #C2C7CF",
  };

  return (
    <>
      <div className="categories_buttons__container">
        <h4 className="categories_heading gilroy">Categories</h4>
        <div className="d-flex flex-column gap-4 mt-4">
          {[
            "Pest Control Services",
            "Home Needs",
            "Home Needs",
            "Home Needs",
            "Home Needs",
            "Home Needs",
            "Home Needs",
            "Home Needs",
          ].map((category, index) => (
            <Button
              key={index}
              sx={
                currentBtn === index + 1
                  ? activeButtonStyle
                  : inactiveButtonStyle
              } // Conditionally apply styles
              varient="outlined"
              className="gilroy text-left px-3 py-2"
              onClick={() => setCurrentBtn(index + 1)} // Update active button on click
            >
              <FontAwesomeIcon icon={faBug} className="px-2" />
              {category}
            </Button>
          ))}
        </div>
      </div>
    </>
  );
};

export default CategoriesButtons;
