export const bidderContent = [
  {
    id: 1,
    title: "Shri Gaurav Pest Control Services",
    certify: "Certified",
    location: "Ibrahimpura, Bhopal, 462001",
    addTime: "2hr",
    about:
      "About: Lorem ipsum dolor sit amet consectetur. Volutpat cras eget enim in...",
    bidAmount: "400",
    defaultValue: "4"
  },
  {
    id: 2,
    title: "Shri Gaurav Pest Control Services",
    certify: "Certified",
    location: "Ibrahimpura, Bhopal, 462001",
    addTime: "2hr",
    about:
      "About: Lorem ipsum dolor sit amet consectetur. Volutpat cras eget enim in...",
    bidAmount: "400",
    defaultValue: "3"
  },
  {
    id: 3,
    title: "Shri Gaurav Pest Control Services",
    certify: "Certified",
    location: "Ibrahimpura, Bhopal, 462001",
    addTime: "2hr",
    about:
      "About: Lorem ipsum dolor sit amet consectetur. Volutpat cras eget enim in...",
    bidAmount: "400",
    defaultValue: "2.5"
  },
  {
    id: 4,
    title: "Shri Gaurav Pest Control Services",
    certify: "Certified",
    location: "Ibrahimpura, Bhopal, 462001",
    addTime: "2hr",
    about:
      "About: Lorem ipsum dolor sit amet consectetur. Volutpat cras eget enim in...",
    bidAmount: "400",
    defaultValue: "4.5"
  },
  {
    id: 5,
    title: "Shri Gaurav Pest Control Services",
    certify: "Certified",
    location: "Ibrahimpura, Bhopal, 462001",
    addTime: "2hr",
    about:
      "About: Lorem ipsum dolor sit amet consectetur. Volutpat cras eget enim in...",
    bidAmount: "400",
    defaultValue: "2"
  },
  {
    id: 6,
    title: "Shri Gaurav Pest Control Services",
    certify: "Certified",
    location: "Ibrahimpura, Bhopal, 462001",
    addTime: "2hr",
    about:
      "About: Lorem ipsum dolor sit amet consectetur. Volutpat cras eget enim in...",
    bidAmount: "400",
    defaultValue: "1"
  },
];
