import React from "react";
import { Button } from "@mui/material";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";

function OrderQuotesButton({ onViewChange, currentView }) {
  return (
    <>
      <div className="orders-button--quotes-sent-button d-flex justify-content-center mt-5">
        <Button
          style={{
            backgroundColor:
              currentView === "orders"
                ? "rgba(214, 236, 255, 1)"
                : "transparent",
            color: "rgba(0, 30, 47, 1)",
            fontSize: "16px",
          }}
          className="orders-button border text-capitalize border-end-0 border-secondary"
          onClick={() => onViewChange("orders")}
        >
          {currentView === "orders" ? (
            <DoneRoundedIcon fontSize="small" style={{ fontSize: "20px" }} />
          ) : (
            ""
          )}
          Orders
        </Button>
        <Button
          style={{
            backgroundColor:
              currentView === "quotes"
                ? "rgba(214, 236, 255, 1)"
                : "transparent",
            color: "rgba(0, 30, 47, 1)",
            fontSize: "16px",
          }}
          className="quotes-sent-button text-capitalize border border border-secondary"
          onClick={() => onViewChange("quotes")}
        >
          {currentView === "quotes" ? (
            <DoneRoundedIcon fontSize="small" style={{ fontSize: "20px" }} />
          ) : (
            ""
          )}
          Quotes Sent
        </Button>
      </div>
    </>
  );
}

export default OrderQuotesButton;
