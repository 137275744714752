import React, { useState } from "react";
import "./ServiceCategoriesContent.css";
import PickService from "./PickService/PickService";
import RequestDetails from "./RequestDetails/RequestDetails";
import Reviews from "./Reviews/Reviews";
import SubCategories from "./SubCategories/SubCategories";
import VirticalStyleLine from "../../VirticalStyleLine/VirticalStyleLine";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
} from "@mui/material";
import SuccessMsg from "./SuccessMsg";

const steps = ["Pick a Service", "Request Details", "Reviews"];

const ServiceCategoriesContent = ({ activeStep, setActiveStep }) => {
  const [auctionSuccess, setAuctionSuccess] = useState(false);

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      // Show success message when "Post the Auction" is clicked
      setAuctionSuccess(true);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  return (
    <>
      <div className="service_category_container d-flex gap-4 mt-5 justify-content-between">
        <aside className="minicategories--container">
          {activeStep === 0 ? (
            <SubCategories />
          ) : (
            <div className="request_details__img rounded-top">
              <div className="request_details__text d-flex justify-content-center align-items-end">
                <Typography
                  sx={{
                    color: "#FA9B00",
                    fontSize: "40px",
                    fontWeight: 600,
                    lineHeight: "47.72px",
                  }}
                  className="gilroy ps-4 pb-5 mb-5"
                >
                  <span style={{ color: "#ffffff" }}>Find the</span> best pest
                  control services!
                </Typography>
              </div>
            </div>
          )}
        </aside>
        <VirticalStyleLine />

        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <React.Fragment>
            {activeStep === 0 && <PickService />}
            {activeStep === 1 && <RequestDetails />}
            {activeStep === 2 && (
              <>
                {auctionSuccess && <SuccessMsg />}
                <Reviews />
              </>
            )}
            <Box sx={{ display: "flex", justifyContent: "center", pt: 2 }}>
              <Button
                onClick={handleNext}
                variant="contained"
                style={{
                  backgroundColor: "#1365AF",
                  color: "#ffffff",
                  padding: "10px 30px",
                }}
              >
                {activeStep === steps.length - 1 ? "Post the Auction" : "Next"}
              </Button>
            </Box>
          </React.Fragment>
        </Box>
      </div>
    </>
  );
};

export default ServiceCategoriesContent;
