import React from "react";
import { Avatar, Button } from "@mui/material";
import PlaceIcon from "@mui/icons-material/Place";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

function Auctions(props) {
  return (
    <div key={props.id} className="carousel border gilroy mb-5 ">
      <div className="carousel-aution-title-addTime d-flex mx-2 justify-content-between align-items-center">
        <div className="carousel-auction-img-title d-flex gap-2 align-items-center ">
          <Avatar
            sx={{ width: 33, height: 33 }}
            src={props.image}
            variant="square"
          />
          <p className="carousel-auction-title gilroy m-0">{props.title}</p>
        </div>
        <span className="auction-time text-lowercase mt-0 mb-4">
          {props.updateTime} ago
        </span>
      </div>
      <div className="carousel-aution-information d-flex m-2 mb-3 justify-content-between align-items-center gap-2">
        <div className="d-flex align-items-center gap-2">
          <Avatar
            className="text-color"
            fontSize="medium"
            sx={{ width: "19px", height: "19px" }}
          />
          <p className="carousel-aution-cusName text-color m-0 ">
            {props.name}
          </p>
        </div>
        <div className="d-flex w-50 align-items-center gap-2">
          <InfoRoundedIcon className="text-color" />
          <p className="carousel-auction-information text-color m-0">
            {props.information}
          </p>
        </div>
      </div>
      <div className="carousel-aution-location-date mx-1 d-flex gap-3">
        <div className="carousel-aution-location d-flex gap-2 w-50">
          <PlaceIcon className="text-color" />
          <p className="carousel-text text-color m-0 pe-2">{props.location}</p>
        </div>
        <div className="carousel-aution-date d-flex gap-2 ">
          <CalendarMonthIcon className="text-color" />
          <p className="carousel-text text-color m-0">{props.date}</p>
        </div>
      </div>
      <div className="carousel-aution-bids-time_left-budget-place_bid-button d-flex justify-content-between mt-3 mx-1 gilroy">
        <div className="d-flex gap-2">
          <p className="carousel-auction-bids rounded-pill my-auto px-3 py-1 fw-bold">
            {props.bids} Bids
          </p>
          <p className="carousel-auction-timeLeft rounded-pill my-auto px-3 py-1 fw-bold">
            {props.timeLeft} Left
          </p>
        </div>

        <div className="carousel-aution-budget-button d-flex gap-2 align-items-center">
          <p className="carousel-aution-budget rounded m-0 py-2">
            Budget: &#8377;{props.budget}
          </p>
          <Button
            variant="contained"
            size="small"
            style={{ fontSize: "18px" }}
            className="carousel-aution-update-button p-1 px-3 text-capitalize"
          >
            Place Bid
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Auctions;
