import React, { useState } from "react";
import "./CustomerOrder.css";
import { orderData } from "../../Dashboard/RecentOrder/OrderCarousel/orderdata.js";
import { Avatar, Button } from "@mui/material";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faMapMarkerAlt,
  faCircle,
  faChevronLeft,
  faChevronRight,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";

import ReactPaginate from "react-paginate";

const itemsPerPage = 4;

function CustomerOrder() {
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const offset = currentPage * itemsPerPage;
  const currentItems = orderData.slice(offset, offset + itemsPerPage);

  return (
    <>
      <div>
        {currentItems.map((item) => (
          <div
            key={item.id}
            className="p-3 px-4 rounded border gilroy mb-4 mt-1 "
          >
            <div className="name-order-id d-flex mx-2 justify-content-between align-items-center">
              <div className="d-flex gap-2 align-items-center ">
                <Avatar src={item.image} />
                <p className="customer-name m-0">{item.name}</p>
                <span className="order-set-time text-lowercase gilroy">
                  <FontAwesomeIcon
                    icon={faCircle}
                    style={{ color: "#6a7d9f", fontSize: "5px" }}
                  />
                  &nbsp;{item.orderSetTime}
                </span>
              </div>
              <div className="d-flex gap-3">
                <p className="order-id m-0 p-0">OrderID: {item.orderId}</p>
                <MoreVertRoundedIcon />
              </div>
            </div>
            <div className="d-flex gap-3 mt-2 mx-2">
              <Button
                style={{
                  color: "rgba(0, 30, 47, 1)",
                  backgroundColor: "rgba(214, 236, 255, 1)",
                  textTransform: "none",
                  fontSize: "15px",
                  fontWeight: "500",
                  height: "30px",
                }}
                size="small"
                className="px-1"
              >
                General Pest Control
              </Button>
              <Button
                style={{
                  color: "rgba(0, 30, 47, 1)",
                  backgroundColor: "rgba(214, 236, 255, 1)",
                  textTransform: "none",
                  fontSize: "15px",
                  fontWeight: "500",
                  height: "30px",
                }}
                size="small"
              >
                Pest Control
              </Button>
            </div>
            <div className=" w-100 d-flex align-items-center justify-content-between mt-3 mb-2">
              <div>
                <div className="order-information d-flex gap-2 align-items-center">
                  <FontAwesomeIcon
                    icon={faCircleInfo}
                    style={{ color: "#6a7d9f", fontSize: "18px" }}
                  />
                  <p className="text content gilroy m-0 my-2">
                    {item.information}
                  </p>
                </div>

                <div className="order-location-date d-flex gap-5">
                  <div className="order-location d-flex gap-2 align-items-center">
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      style={{ color: "#6a7d9f", fontSize: "18px" }}
                    />
                    <p className="text content gilroy m-0 my-2 pe-2">
                      {item.location}
                    </p>
                  </div>
                  <div className="order-date d-flex gap-2 align-items-center">
                    <FontAwesomeIcon
                      icon={faCalendarAlt}
                      style={{ color: "#6a7d9f", fontSize: "18px" }}
                    />

                    <p className="text content m-0 my-2 gilroy">{item.date}</p>
                  </div>
                </div>
              </div>
              <p className="order-price gilroy m-0 gilroy mt-2">
                &#8377;{item.price}
              </p>
            </div>
            <div className="order-price-status-button d-flex justify-content-end mt-1 mx-3 gilroy">
              <div className="order-status-button d-flex gap-2 align-items-center">
                <p className="order-status rounded-pill m-0 gilroy">
                  Status: {item.status}
                </p>
                <Button
                  variant="contained"
                  size="small"
                  style={{ fontSize: "18px", padding: "0px 20px 0px 20px" }}
                  className="order-update-button text-capitalize"
                >
                  Update
                </Button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <ReactPaginate
        previousLabel={
          <FontAwesomeIcon
            icon={faChevronLeft}
            style={{ color: "#001b3d", fontSize: "16px" }}
          />
        }
        nextLabel={
          <FontAwesomeIcon
            icon={faChevronRight}
            style={{ color: "#001b3d", fontSize: "16px" }}
          />
        }
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={Math.ceil(orderData.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
        pageClassName="pt-1"
        previousClassName=" pt-1"
        nextClassName=" pt-1"
      />
    </>
  );
}

export default CustomerOrder;
