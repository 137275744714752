// productsData.js
const products = [
  {
    id: 1,
    title: "Unbranded Dinning Set with 8 chairs and 1 table",
    quantity: "2",
    originalPrice: 12999,
    discountedPrice: 10999,
    rating: 2.5,
    reviews: "12.5k",
    image: "images/home/services/automobile/1.png",
    delivery: "Delivery in 4 to 5 days.",
  },
  {
    id: 2,
    title: "Unbranded Dinning Set with 8 chairs and 1 table",
    quantity: "2",
    originalPrice: 12999,
    discountedPrice: 10999,
    rating: 2.5,
    reviews: "12.5k",
    image: "images/home/services/automobile/1.png",
    delivery: "Delivery in 4 to 5 days.",
  },
  {
    id: 3,
    title: "Unbranded Dinning Set with 8 chairs and 1 table",
    quantity: "2",
    originalPrice: 12999,
    discountedPrice: 10999,
    rating: 2.5,
    reviews: "12.5k",
    image: "images/home/services/automobile/1.png",
    delivery: "Delivery in 4 to 5 days.",
  },
  {
    id: 4,
    title: "Unbranded Dinning Set with 8 chairs and 1 table",
    quantity: "2",
    originalPrice: 12999,
    discountedPrice: 10999,
    rating: 2.5,
    reviews: "12.5k",
    image: "images/home/services/automobile/1.png",
    delivery: "Delivery in 4 to 5 days.",
  },
  {
    id: 5,
    title: "Unbranded Dinning Set with 8 chairs and 1 table",
    quantity: "2",
    originalPrice: 12999,
    discountedPrice: 10999,
    rating: 2.5,
    reviews: "12.5k",
    image: "images/home/services/automobile/1.png",
    delivery: "Delivery in 4 to 5 days.",
  },
  {
    id: 6,
    title: "Unbranded Dinning Set with 8 chairs and 1 table",
    quantity: "2",
    originalPrice: 12999,
    discountedPrice: 10999,
    rating: 2.5,
    reviews: "12.5k",
    image: "images/home/services/automobile/1.png",
    delivery: "Delivery in 4 to 5 days.",
  },
  {
    id: 7,
    title: "Unbranded Dinning Set with 8 chairs and 1 table",
    quantity: "2",
    originalPrice: 12999,
    discountedPrice: 10999,
    rating: 2.5,
    reviews: "12.5k",
    image: "images/home/services/automobile/1.png",
    delivery: "Delivery in 4 to 5 days.",
  },
  {
    id: 8,
    title: "Unbranded Dinning Set with 8 chairs and 1 table",
    quantity: "2",
    originalPrice: 12999,
    discountedPrice: 10999,
    rating: 2.5,
    reviews: "12.5k",
    image: "images/home/services/automobile/1.png",
    delivery: "Delivery in 4 to 5 days.",
  },
  {
    id: 9,
    title: "Unbranded Dinning Set with 8 chairs and 1 table",
    quantity: "2",
    originalPrice: 12999,
    discountedPrice: 10999,
    rating: 2.5,
    reviews: "12.5k",
    image: "images/home/services/automobile/1.png",
    delivery: "Delivery in 4 to 5 days.",
  },
];

export default products;
