import React from "react";
import "./OrderCarousel.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Order from "./Order";
import { orderData } from "./orderdata.js";
import { responsive } from "./orderdata.js";

const OrderCarousel = () => {
  const carouselItems = orderData.map((orderData) => (
    <Order
      id={orderData.id}
      name={orderData.name}
      orderId={orderData.orderId}
      information={orderData.information}
      location={orderData.location}
      date={orderData.date}
      price={orderData.price}
      status={orderData.status}
      image={orderData.image}
    />
  ));
  return (
    <Carousel
      swipeable={false}
      draggable={false}
      showDots={true}
      responsive={responsive}
      infinite={true}
      autoPlay={true}
      removeArrowOnDeviceType={["desktop", "mobile", "tablet"]}
      autoPlaySpeed={1000}
      transitionDuration={1000}
    >
      {carouselItems}
    </Carousel>
  );
};

export default OrderCarousel;
