import React from "react";
import "./RecentAuctions.css";
import { Button } from "@mui/material";
import AuctionsCarousel from "./AuctionsCarousel/AuctionsCarousel";

function RecentAuctions() {
  return (
    <>
      <div className="recent-auction-box mt-5">
        <div className="recent-auctions-heading-seeAllButton d-flex justify-content-between">
          <h1 style={{ fontSize: "45px" }} className="heading gilroy mt-1">
            Recent Auctions
          </h1>
          <Button
            style={{
              backgroundColor: "rgba(208, 223, 255, 1)",
              color: "rgba(0,0,0,1)",
            }}
            className="text-capitalize fs-5 mb-4 mt-1 p-1 px-3 py-2"
          >
            See All
          </Button>
        </div>
        <div className="recent-auctions-carousel mt-1">
          <AuctionsCarousel />
        </div>
      </div>
    </>
  );
}

export default RecentAuctions;
