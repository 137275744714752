import React from "react";
import "./MyAuctions.css";
import { ArrowBackRounded } from "@mui/icons-material";
import MyAuctionPaginate from "./MyAuctionPaginate/MyAuctionPaginate";

const MyAuctions = () => {
  return (
    <>
      <div className="my_auctions__container">
        <div className="d-flex gap-3">
          <ArrowBackRounded className="back-arrow mt-2 fs-2 fw-bold" />
          <div className="">
            <h1 className="heading fw-bold gilroy">Auctions</h1>
            <p
              style={{ fontSize: "16px", fontWeight: 600, color: "#6A7D9F" }}
              className="m-0 gilroy"
            >
              Home / My Auction
            </p>
          </div>
        </div>
        <div>
          <MyAuctionPaginate />
        </div>
      </div>
    </>
  );
};

export default MyAuctions;
