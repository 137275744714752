import React from "react";
import "./MyOrders.css";
import { ArrowBackRounded } from "@mui/icons-material";
import MyOrdersPaginate from "./MyOrdersPaginate/MyOrdersPaginate";

const MyOrders = () => {
  return (
    <>
      <div className="my_orders_container">
        <div className="d-flex gap-3">
          <ArrowBackRounded className="back-arrow mt-2 fs-2 fw-bold" />
          <div className="">
            <h1 className="heading fw-bold gilroy">Orders</h1>
            <p
              style={{ fontSize: "16px", fontWeight: 600, color: "#6A7D9F" }}
              className="m-0 gilroy"
            >
              Home / My Orders
            </p>
          </div>
        </div>
        <div>
          <MyOrdersPaginate />
        </div>
      </div>
    </>
  );
};

export default MyOrders;
