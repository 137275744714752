import React from "react";
import "./OrderPage.css";
import { ArrowBackRounded } from "@mui/icons-material";

import OrderPageProfileStatus from "./OrderPageProfileAndStatus/OrderPageProfileStatus";
import OrderServicesPrice from "./OrderServicesAndPrice/OrderServicesPrice";

const OrderPage = () => {
  return (
    <>
      <div className="my_order_page__container">
        <div className="d-flex gap-3">
          <ArrowBackRounded className="back-arrow mt-2 fs-2 fw-bold" />
          <div className="">
            <h1 className="heading fw-bold gilroy">Order Page</h1>
            <p
              style={{ fontSize: "16px", fontWeight: 600, color: "#6A7D9F" }}
              className="m-0 gilroy"
            >
              Home / My Orders / Order Page / Order Id: 1638910923
            </p>
          </div>
        </div>
        <OrderPageProfileStatus />
        <OrderServicesPrice />
      </div>
    </>
  );
};

export default OrderPage;
