import React, { useState, useEffect } from "react";
import { Button, TextField, useMediaQuery } from "@mui/material";
import "./PanValidation.css";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {
  getLabelStyle,
  getButtonPadding,
  getPlaceholderFontSize,
  getContainerClass,
  getTextFieldStyle,
} from "./PanValidationStyles"; // Import styles

const PanValidation = ({ data, businessData, onSave }) => {
  // Screen size for responsiveness -------------------
  const isMediumScreen = useMediaQuery(
    "(min-width: 768px) and (max-width: 992px)"
  );
  const isLargeScreen = useMediaQuery("(max-width: 992px)");
  const isVerySmallScreen = useMediaQuery("(max-width: 435px)");
  const isBetween768And883 = useMediaQuery(
    "(min-width: 768px) and (max-width: 883px)"
  );

  // useState hooks for managing form fields
  const [businessPan, setBusinessPan] = useState("");
  const [bussPanName, setBussPanName] = useState("");
  const [dob, setDob] = useState(null);
  const [companyType, setCompanyType] = useState("");
  const [isData, setIsData] = useState(false);

  useEffect(() => {
    if (businessData) {
      const autoCompanyType = businessData.companyType;
      setCompanyType(autoCompanyType);
    }
  }, [businessData]);

  useEffect(() => {
    if (data) {
      const autoFillName = data.panName;
      const autoDateOfBirth = data.panDob;
      const panNo = data.panNo;

      setBusinessPan(panNo);
      setBussPanName(autoFillName);
      setDob(autoDateOfBirth ? dayjs(autoDateOfBirth) : null);
      setIsData(true);
    }
  }, [data]);

  // onChange handlers (only active if isData is false)
  const handleBusinessPanChange = (event) => {
    if (!isData) {
      setBusinessPan(event.target.value);
    }
  };

  const handleBussPanNameChange = (event) => {
    if (!isData) {
      setBussPanName(event.target.value);
    }
  };

  const handleDobChange = (date) => {
    if (!isData) {
      setDob(date);
    }
  };

  const handleSave = () => {
    const panValidData = {
      businessType: companyType,
      bussPanNo: businessPan,
      bussPanName: bussPanName,
      bussPanDob: dob ? dayjs(dob).format("YYYY-MM-DD") : "",
    };

    onSave(panValidData);
  };

  // Apply styles from PanValidationStyles.js
  const labelStyle = getLabelStyle(isLargeScreen);
  const buttonPadding = getButtonPadding(isMediumScreen, isVerySmallScreen);
  const placeholderFontSize = getPlaceholderFontSize(
    isMediumScreen,
    isVerySmallScreen
  );
  const containerClass = getContainerClass(isBetween768And883);
  const textFieldStyle = getTextFieldStyle(isData, placeholderFontSize);

  return (
    <>
      <div className="pan_validation_container mx-0 my-1 w-100 h-auto">
        <p
          style={{
            fontSize: isVerySmallScreen ? "16px" : "18px",
            textTransform: "none !important",
          }}
          className="text-sm-center gilroy-regular"
        >
          Business PAN number entered here will be used for Tax purposes
        </p>
        <div
          className={`organization_type__business_name__container mb-4 ${containerClass}`}
        >
          <div className="business-type w-100">
            <p
              style={labelStyle}
              className="label_name fw-bold my-auto ms-2 mt-2 gilroy text-dark"
            >
              Organization/Business Type*
            </p>
            <div className="mt-1">
              <TextField
                sx={{
                  "& .Mui-disabled": {
                    cursor: !isData ? "text" : "not-allowed",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    fontSize: placeholderFontSize,
                  },
                }}
                size="small"
                value={companyType}
                fullWidth
                disabled
              />
            </div>
          </div>
          <div className="business--pan w-100">
            <p
              style={labelStyle}
              className="label_name fw-bold my-auto ms-2 mt-2 gilroy text-dark"
            >
              Business PAN*
            </p>
            <div className="mt-1">
              <TextField
                sx={textFieldStyle}
                fullWidth
                size="small"
                placeholder="Enter Business PAN Number"
                value={businessPan}
                onChange={handleBusinessPanChange}
                disabled={isData}
              />
            </div>
          </div>
        </div>
        <div className={`name__dob_as_in_pan__container ${containerClass}`}>
          <div className="name__as_in_pan w-100">
            <p
              style={labelStyle}
              className="label_name fw-bold my-auto ms-2 mt-2 gilroy text-dark"
            >
              Name (as in PAN)*
            </p>
            <div className="mt-1">
              <TextField
                sx={textFieldStyle}
                fullWidth
                size="small"
                placeholder="Enter your Name"
                value={bussPanName}
                onChange={handleBussPanNameChange}
                disabled={isData}
              />
            </div>
          </div>
          <div className="date__as_in_pan w-100">
            <p
              style={labelStyle}
              className="label_name fw-bold my-auto ms-2 mt-2 gilroy text-dark"
            >
              Date (As in PAN)*
            </p>
            <div className="mt-1">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="custom-date-picker"
                  value={dob}
                  onChange={handleDobChange}
                  size="small"
                  fullWidth
                  disabled={isData}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
          </div>
        </div>
        <div className="verify_pan__btn mt-4">
          <Button
            sx={{
              backgroundColor: "#1365AF",
              padding: buttonPadding,
              color: "#ffffff",
              float: "right",
              textTransform: "none",
            }}
            variant="contained"
            onClick={handleSave}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

export default PanValidation;
