import React, { useState } from "react";
import "./Orders.css";
import { Box } from "@mui/material";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import Headings from "../Headings/Headings";
import OrderQuotesButton from "./OrderQuotesButton/OrderQuotesButton";
import FilterOrders from "./FilterOrders";
import SortSelect from "./SortSelect";
import CustomerOrder from "./CustOrders/CustomerOrder";
import QuotesSentOrder from "./QuotesSent/QuotesSentOrder";

function Orders() {
  const [searchText, setSearchText] = useState("");
  const [view, setView] = useState("orders"); // state to manage the view

  const handleInputChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleViewChange = (view) => {
    setView(view);
  };

  return (
    <>
      <div className="orders-box gilroy">
        <Headings headings="Orders" />
        <OrderQuotesButton onViewChange={handleViewChange} currentView={view} />
        <div className="d-flex justify-content-between align-items-center mt-5">
          <div className="d-flex w-auto flex-wrap gap-2">
            <FilterOrders />
          </div>
          <div className="d-flex gap-2">
            <SearchRoundedIcon
              style={{ color: "rgba(106, 125, 159, 1)" }}
              className="fs-2"
            />
            <SortSelect />
          </div>
        </div>
        <div className="mt-0 mx-4 p-5">
          <div className="search-bar border px-1 d-flex align-items-center justify-content-center">
            <SearchRoundedIcon style={{ color: "rgba(106, 125, 159, 1)" }} />
            <Box sx={{ m: 0, width: "100%" }}>
              <input
                className="w-100 fs-5 fw-medium"
                type="text"
                id="searchForService"
                placeholder="Search For Service"
                value={searchText}
                onChange={handleInputChange}
              />
            </Box>
          </div>
        </div>
        {view === "orders" && <CustomerOrder />}
        {view === "quotes" && <QuotesSentOrder />}
      </div>
    </>
  );
}

export default Orders;
