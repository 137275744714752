import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import "./Carousel.css";
import { Button } from "@mui/material";

const CarouselPage = ({ data }) => {
  const [isData, setIsData] = useState(false);
  console.log("carousel", data.slider);

  useEffect(() => {
    if (data && data.slider) {
      setIsData(true);
    }
  }, [data]);

  const design = (
    <>
      <Carousel className="carousel_home__page mt-5 w-100 px-0 mx-0">
        {isData &&
          data.slider.map((item) => (
            <Carousel.Item key={item._id}>
              <img
                alt={item.title}
                // alt=""
                // src="images/home/carousel/a.png"
                src={item.path}
                className="d-block w-100 "
                style={{ height: "60vh" }}
              />
              <Carousel.Caption>
                <h3>
                  <span style={{ color: "#1365AF" }}>
                    Looking for services?{" "}
                  </span>
                  <br />
                  <span style={{ color: "#000000" }}>
                    Well, look no further!
                  </span>
                </h3>

                <Button
                  style={{ background: "#1365AF", color: "white" }}
                  className="gilroy px-3"
                >
                  Start a Bidding
                </Button>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
      </Carousel>
    </>
  );
  return design;
};

export default CarouselPage;
