import React from "react";
import "./QuickAction.css";
import SettingsIcon from "@mui/icons-material/Settings";
import { Button } from "@mui/material";
import Actions from "./Actions";

function QuickAction() {
  return (
    <>
      <div className="quick-action-box">
        <div className="d-flex justify-content-between">
          <h1 style={{ fontSize: "45px" }} className="heading gilroy">
            Quick Actions
          </h1>
          <Button>
            <SettingsIcon
              style={{ color: "rgba(106, 125, 159, 1)" }}
              fontSize="large"
            />
          </Button>
        </div>
        <div className="actions mt-4 d-flex gap-5">
          <Actions />
          <Actions />
          <Actions />
        </div>
      </div>
    </>
  );
}

export default QuickAction;
