import React from "react";
import "./Profile.css";
import ProfileCarousels from "./ProfileCarousels/ProfileCarousels";
import ShareButton from "./ShareButton";
import BusinessInformation from "./BusinessInformation/BusinessInformation";
import ContactInformation from "./ContactInformation/ContactInformation";
import Overview from "./Overview/Overview";
import ServiceOffered from "./ServiceOffered/ServiceOffered";
import SocialLinks from "./SocialLinks/SocialLinks";
import Certification from "./Certification/Certification";

function Profile() {
  return (
    <>
      <div className="mb-0">
        <ShareButton />
        <ProfileCarousels />
        <BusinessInformation />
        <ContactInformation />
        <Overview />
        <ServiceOffered />
        <SocialLinks />
        <Certification />
      </div>
    </>
  );
}

export default Profile;
