import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { AddBoxRounded } from "@mui/icons-material";
import CategoriesButtons from "./CategoriesButton/CategoriesButtons";
import Categories from "./Categories/Categories";
import VirticalStyleLine from "../../VirticalStyleLine/VirticalStyleLine";

const MyServices = () => {
  const navigate = useNavigate();
  const handleAddServiceData = () => {
    navigate("/add_services");
  };
  const handleAddService = () => {
    navigate("/add_services_details");
  };
  return (
    <>
      <div className="my_services__container mt-4">
        <div className="add_my_service__button d-flex gap-4">
          <Button
            sx={{
              fontSize: "20px",
              color: "#1365AF",
              lineHeight: "28.8px",
              fontWeight: 400,
              backgroundColor: "#ECF0FF",
            }}
            onClick={handleAddService}
            className="gilroy p-3 px-5"
          >
            <AddBoxRounded />
            &nbsp;&nbsp;Add Services
          </Button>
          <Button
            sx={{
              fontSize: "20px",
              color: "#1365AF",
              lineHeight: "28.8px",
              fontWeight: 400,
              backgroundColor: "#ECF0FF",
            }}
            onClick={handleAddServiceData}
            className="gilroy p-3 px-5"
          >
            <AddBoxRounded />
            &nbsp;&nbsp;Add Services Data
          </Button>
        </div>
        <div className="d-flex justify-content-around mt-5">
          <div className="d-none d-md-block">
            <CategoriesButtons />
          </div>
          <VirticalStyleLine />
          <div style={{ width: "65%" }} className="categories_container">
            <Categories />
          </div>
        </div>
      </div>
    </>
  );
};

export default MyServices;
