import React from "react";
import Box from "@mui/material/Box";
import {
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { KeyboardArrowDown, FilterListRounded } from "@mui/icons-material";
import MyAuctionFilter from "../../../MyAuctionFilter/MyAuctionFilter";

const CustomKeyboardArrowDownIcon = styled(KeyboardArrowDown)(({ theme }) => ({
  color: "rgba(0, 70, 139, 1)",
  fontSize: "18px",
  marginRight: "12px",
}));

const CustomSelect = styled(Select)(({ theme }) => ({
  backgroundColor: "rgba(208, 223, 255, 1)",
  borderRadius: "4px",
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(0, 70, 139, 1)",
    borderWidth: "1px",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(0, 70, 139, 1)",
    borderWidth: "1px",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(0, 70, 139, 1)",
    borderWidth: "1px",
  },
  "& .MuiSvgIcon-root": {
    color: "rgba(0, 70, 139, 1)",
  },
  "& .MuiSelect-select": {
    padding: "4px 17px",
    fontSize: "0.875rem",
  },
}));

const MyAuctionBidsFilter = () => {
  const [selectedOptions, setSelectedOptions] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedOptions(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <Box sx={{ minWidth: 120 }} className="me-2">
      <FormControl fullWidth>
        <CustomSelect
          id="rilter_result"
          multiple
          value={selectedOptions}
          displayEmpty
          size="small"
          onChange={handleChange}
          IconComponent={CustomKeyboardArrowDownIcon}
          inputProps={{ "aria-label": "Select an age" }}
          renderValue={() => (
            <p
              style={{ color: "rgba(0, 70, 139, 1)" }}
              className="fw-bold gilroy text-center m-0 pe-3"
            >
              <FilterListRounded fontSize="small" />
              &nbsp;&nbsp;Filter
            </p>
          )}
        >
          <MenuItem value="" disabled>
            <span
              style={{ color: "rgba(0, 70, 139, 1)" }}
              className="fw-bold gilroy"
            >
              Filter
            </span>
          </MenuItem>
          {["Ten", "Twenty", "Thirty"].map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={selectedOptions.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </CustomSelect>
      </FormControl>
    </Box>
  );
};

export default MyAuctionBidsFilter;
