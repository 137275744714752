export const subCategoriesData = [
    {
        id: 1,
        img: "images/homeService/subcateg/sub-categ1.jpg",
        title: "Domestic Help",
        subtitle: "45 Service providers",
    },
    {
        id: 2,
        img: "images/homeService/subcateg/sub-categ1.jpg",
        title: "Furniture Dealers & Repair",
        subtitle: "45 Service providers",
    },
    {
        id: 3,
        img: "images/homeService/subcateg/sub-categ1.jpg",
        title: "Gardeners & Horticulture",
        subtitle: "45 Service providers",
    },
    {
        id: 4,
        img: "images/homeService/subcateg/sub-categ1.jpg",
        title: "Carpenters",
        subtitle: "45 Service providers",
    },
    {
        id: 5,
        img: "images/homeService/subcateg/sub-categ1.jpg",
        title: "Packers and Movers",
        subtitle: "45 Service providers",
    },
    {
        id: 6,
        img: "images/homeService/subcateg/sub-categ1.jpg",
        title: "Gardeners & Horticulture",
        subtitle: "45 Service providers",
    },
    {
        id: 7,
        img: "images/homeService/subcateg/sub-categ1.jpg",
        title: "Carpenters",
        subtitle: "45 Service providers",
    },
]