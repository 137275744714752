import React, { useState } from "react";
import "./RequestDetails.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextField,
  InputAdornment,
  useMediaQuery,
} from "@mui/material";
import { CurrencyRupee } from "@mui/icons-material";
import { BpIcon, BpCheckedIcon } from "./BpIcon";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

function BpRadio(props) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

const RequestDetails = () => {
  // Screen Size for responsiveness-----------------
  const isBet769and992 = useMediaQuery(
    "(min-width:769px)and(max-width:992px) "
  );

  const [budget, setBudget] = useState(1960);

  const handleSliderChange = (event) => {
    setBudget(event.target.value);
  };

  const labelStyle = { color: "#343538", fontSize: "20px", fontWeight: 600 };

  return (
    <>
      <div className="request_details__container my-5 ">
        <div className="w-100 d-flex">
          <p className="request_details__filter_result gilroy p-2 px-4 rounded-pill border ">
            <FontAwesomeIcon style={{ fontSize: "14px" }} icon={faXmark} />
            &nbsp;&nbsp;General Pest Control
          </p>
        </div>
        <div className="mt-4">
          <FormControl>
            <FormLabel
              id="demo-customized-radios"
              sx={labelStyle}
              className="gilroy"
            >
              Porperty Size
            </FormLabel>

            <RadioGroup
              defaultValue="female"
              aria-labelledby="demo-customized-radios"
              name="customized-radios"
              className="px-2"
            >
              <FormControlLabel
                value="oneBhk"
                control={<BpRadio />}
                label="1 BHK"
              />
              <FormControlLabel
                value="twoBhk"
                control={<BpRadio />}
                label="2 BHK"
              />
              <FormControlLabel
                value="threeBhk"
                control={<BpRadio />}
                label="3 BHK"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div className="mt-4">
          <p className="gilroy-regular m-0" style={labelStyle}>
            Enter your address
          </p>
          <TextField
            size="small"
            sx={{ backgroundColor: "#FFFFFF" }}
            fullWidth
            placeholder="Search your Address"
          />
        </div>
        <div className="mt-4">
          <p className="gilroy-regular m-0" style={labelStyle}>
            Special instructions*
          </p>
          <TextField
            size="small"
            fullWidth
            multiline
            minRows={5}
            placeholder="Text"
            sx={{ backgroundColor: "#FFFFFF" }}
          />
        </div>
        <div className="mt-4 d-flex flex-column flex-sm-row flex-md-column flex-lg-row justify-content-between gap-4 w-100">
          <div className="w-100">
            <p className="gilroy-regular m-0" style={labelStyle}>
              Pick a date
            </p>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className="custom-date-picker"
                size="small"
                fullWidth
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
          <div className="w-100 ">
            <p className="gilroy-regular m-0" style={labelStyle}>
              Pick time
            </p>
            <TextField
              size="small"
              sx={{ backgroundColor: "#FFFFFF" }}
              fullWidth
              placeholder="Search your Address"
            />
          </div>
        </div>
        <div className="mt-4">
          <p className="gilroy-regular m-0" style={labelStyle}>
            Budget
          </p>
          <TextField
            size="small"
            sx={{ backgroundColor: "#FFFFFF" }}
            placeholder="Search your Address"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CurrencyRupee sx={{ width: 22, height: 22 }} />
                </InputAdornment>
              ),
            }}
          />

          {/* Budget Range  */}
          <div className="budget-slider mt-4 w-100">
            <input
              type="range"
              id="budget-input"
              min="500"
              max="10000"
              value={budget}
              onChange={handleSliderChange}
              style={{
                width: "100%", // Adjust width as needed
                height: "5px",
                borderRadius: "5px",
                backgroundColor: "#ccc",
                appearance: "none",
                cursor: "pointer",
                outline: "none",
              }}
            />
            <div className="budget-value">INR {budget}</div>
          </div>
          <div className="w-100 mt-4">
            <p className="gilroy-regular m-0" style={labelStyle}>
              How long do you want to run the auction?
            </p>
            <TextField
              size="small"
              sx={{ backgroundColor: "#FFFFFF" }}
              placeholder="2 Days"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestDetails;
