import React from "react";
import "./ProfileSteps.css";
import { Button, useMediaQuery } from "@mui/material";

const ProfileSteps = ({ currentStep, onStepClick }) => {
  // const isMediumScreen = useMediaQuery("(min-width:1200px)");
  // // const isSmallScreen = useMediaQuery("(max-width:1200px)");
  const isSmallScreen = useMediaQuery("(max-width:992px)");

  // const styleButton = (steps) => ({
  //   border: "2px solid rgba(255,199,199)",
  //   textTransform: "none",
  //   fontSize: isSmallScreen ? "14px" : "16px",
  //   color: "#000000",
  //   width: isSmallScreen ? "13rem" : "14rem",
  //   marginTop: "10px",
  //   background: "rgb(255, 246, 246)",
  //   // box-shadow: 0px 5px 9px -8px;
  //   boxShadow: "0px 5px 9px -8px",
  // });

  const baseStyle = {
    textTransform: "none",
    fontSize: isSmallScreen ? "14px" : "16px",
    color: "#000000",
    fontWeight: 600,
    width: isSmallScreen ? "13rem" : "14rem",
    marginTop: "10px",
    boxShadow: "0 5px 9px -8px",
  };

  const styleButton = (step) => {
    let style = {
      ...baseStyle,
      border: "2px solid #a0a0a0",
      backgroundColor: "#fbfbfb",
    };
    if (step < currentStep) {
      style = {
        ...baseStyle,
        border: "2px solid #33ff3f",
        backgroundColor: "#e6ffe8",
      };
    } else if (step === currentStep) {
      style = {
        ...baseStyle,
        border: "2px solid #2899fb",
        backgroundColor: "#D6ECFF",
      };
    }
    return style;
  };

  return (
    <div
      style={{
        marginTop: "2rem",
      }}
      className=" seller_profile_steps__container d-flex flex-column justify-content-center align-items-center p-0 "
    >
      <Button
        variant="outlined"
        style={styleButton(1)}
        className="gilroy mx-0"
        onClick={() => onStepClick(1)}
      >
        Pan Validation
      </Button>
      <Button
        variant="outlined"
        style={styleButton(2)}
        className="gilroy mx-0"
        onClick={() => onStepClick(2)}
      >
        Business Details
      </Button>
      <Button
        variant="outlined"
        style={styleButton(3)}
        className="gilroy mx-0"
        onClick={() => onStepClick(3)}
      >
        Additional Details
      </Button>
      <Button
        variant="outlined"
        style={styleButton(4)}
        className="gilroy mx-0"
        onClick={() => onStepClick(4)}
      >
        Office Locations
      </Button>
      <Button
        variant="outlined"
        style={styleButton(5)}
        className="gilroy mx-0"
        onClick={() => onStepClick(5)}
      >
        Bank Accounts
      </Button>
      <Button
        variant="outlined"
        style={styleButton(6)}
        className="gilroy mx-0"
        onClick={() => onStepClick(6)}
      >
        Tax Assessments
      </Button>
      <Button
        variant="outlined"
        style={styleButton(7)}
        className="gilroy mx-0"
        onClick={() => onStepClick(7)}
      >
        e-Invoicing
      </Button>
    </div>
  );
};

export default ProfileSteps;
