import React from "react";
import "./SubCategories.css";
import { Button } from "@mui/material";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouseChimney,
  faBriefcase,
  faGraduationCap,
  faFile,
  faBuilding,
  faStarOfLife,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";

const SubCategories = () => {
  const sx = {
    backgorundColor: "rgba(250, 249, 253, 1)",
    padding: "14px 16px",
    fontSize: 20,
    color: "rgba(66, 71, 78, 1)",
    lineHeight: "23.54px",
    fontWeight: 500,
    border: "rgba(194, 199, 207, 1)",
  };

  const style =
    "service__mini--categories__buttons justify-content-start border gap-3 rounded w-100 gilroy";
  return (
    <>
      <h3 className="service--categories__minicateg">Categories</h3>
      <div className="service__mini--categories__container mt-4 d-flex flex-column gap-2">
        <Button sx={sx} className={style}>
          <FontAwesomeIcon
            icon={faHouseChimney}
            style={{ color: "#21272a", fontSize: "20px" }}
          />
          Home Services
        </Button>

        <Button sx={sx} className={style}>
          <FontAwesomeIcon
            icon={faBriefcase}
            style={{ color: "#21272a", fontSize: "20px" }}
          />
          Business Services
        </Button>
        <Button sx={sx} className={style}>
          <DirectionsCarIcon
            sx={{
              color: "#21272a",
              fontSize: "22px",
            }}
          />
          Automobile Services
        </Button>
        <Button sx={sx} className={style}>
          <FontAwesomeIcon
            icon={faGraduationCap}
            style={{ color: "#21272a", fontSize: "20px" }}
          />
          Education Training
        </Button>
        <Button sx={sx} className={style}>
          <FontAwesomeIcon
            icon={faFile}
            style={{ color: "#21272a", fontSize: "20px" }}
          />
          Document Services
        </Button>
        <Button sx={sx} className={style}>
          <FontAwesomeIcon
            icon={faBuilding}
            style={{ color: "#21272a", fontSize: "20px" }}
          />
          Properties & Rent
        </Button>
        <Button sx={sx} className={style}>
          <FontAwesomeIcon
            icon={faStarOfLife}
            style={{ color: "#21272a", fontSize: "20px" }}
          />
          Health & Wellness
        </Button>
        <Button sx={sx} className={style}>
          <FontAwesomeIcon
            icon={faCalendarAlt}
            style={{ color: "#21272a", fontSize: "20px" }}
          />
          Events & Occasions
        </Button>
      </div>
    </>
  );
};

export default SubCategories;
