import React, { useState } from "react";
import "./ProductsAndServices.css";
import { ArrowBackRounded } from "@mui/icons-material";
import { Button } from "@mui/material";
import MyProducts from "./MyProducts/MyProducts";
import MyServices from "./MyServices/MyServices";

const ProductsAndServices = () => {
  const [activeTab, setActiveTab] = useState("products");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <div className="products__services___container">
        <div className="d-flex gap-3">
          <ArrowBackRounded className="back-arrow mt-2 fs-2 fw-bold" />
          <div className="">
            <h1 className="heading fw-bold gilroy">Products & Services</h1>
            <p
              style={{ fontSize: "16px", fontWeight: 600, color: "#6A7D9F" }}
              className="m-0 gilroy"
            >
              Home / Products & Services
            </p>
          </div>
        </div>
        <section className="products__services___buttons mt-5">
          <div className="d-flex gap-4">
            <Button
              sx={{
                textDecoration: "none",
                fontSize: "24px",
                fontWeight: activeTab === "products" ? 600 : 400,
                color: "#1365AF",
              }}
              className="gilroy"
              onClick={() => handleTabClick("products")}
            >
              My Products
            </Button>
            <Button
              sx={{
                textDecoration: "none",
                fontSize: "24px",
                fontWeight: activeTab === "services" ? 600 : 400,
                color: "#1365AF",
              }}
              className="gilroy"
              onClick={() => handleTabClick("services")}
            >
              My Services
            </Button>
          </div>
          <div className="links__underline_container px-1">
            <div
              className={`underline ${
                activeTab === "services" ? "move-right" : "move-left"
              }`}
            ></div>
          </div>
        </section>
        <div>{activeTab === "products" ? <MyProducts /> : <MyServices />}</div>
      </div>
    </>
  );
};

export default ProductsAndServices;
