import React, { useEffect, useState } from "react";
import "./AddServiceDetails.css";
import {
  ArrowBackRounded,
  KeyboardArrowDown,
  ConstructionRounded,
} from "@mui/icons-material";
import { TextField, Autocomplete, styled, Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import SubCategories from "./MultilevelDropdown/SubCategories";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faXmark } from "@fortawesome/free-solid-svg-icons";
import { profileCategoriesAPI } from "../../../../Config/apiConfig";
// import { loginCredentials } from "../../ZReusableData/ResuableData";
import { createServices } from "../../../../Config/apiConfig";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddServiceDetails = () => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [token, setToken] = useState("");
  const [dropDownContent, setDropDownContent] = useState("");
  const [childCategoryDetailId, setChildCategoryDetialId] = useState("");
  const [childCategoryDetailName, setChildCategoryDetailName] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [license, setLicense] = useState(null);
  const [service, setService] = useState(null);
  // const [documentDetailsData, setDocumentDetailsData] = useState("");

  // const createServices = baseUrl + "/service/createServices";

  const CustomTextField = styled(TextField)({
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
      },
    },
  });

  const handleDocumentDetailsData = (data) => {
    console.log("doc data", data);
    console.log("uploaded Photo id", data.childCategoryId);
    console.log("uploaded photo name", data.childCategoryName);
    setChildCategoryDetialId(data.childCategoryId);
    setChildCategoryDetailName(data.childCategoryName);
    setLicense(data.licenseDocument);
    setService(data.serviceDocument);
  };

  console.log("service", service);

  useEffect(() => {
    const fetchProfileCategoriesAPI = async () => {
      try {
        const loginResponse = localStorage.getItem("loginCredentials");
        if (loginResponse) {
          const parsedData = JSON.parse(loginResponse);
          setToken(parsedData.token);
        }
        const response = await axios.get(profileCategoriesAPI, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("categories data", response.data);
        setDropDownContent(response.data.data);
      } catch (error) {
        console.log("error in api ", error);
      }
    };
    fetchProfileCategoriesAPI();
  }, [token]);

  // Log item._id and item.name on click of service card
  const handleCardClick = (item) => {
    setCategoryId(item._id);
    setCategoryName(item.name);
    console.log("Service ID:", item._id);
    console.log("Service Name:", item.name);
  };

  useEffect(() => {
    const formData = new FormData();
    formData.append("categoryId", categoryId);
    formData.append("childCategoryId", childCategoryDetailId);
    formData.append("categoryName", categoryName);
    formData.append("childCategoryName", childCategoryDetailName);
    formData.append("license", license);
    formData.append("service", service);
    console.log("Form Data ", formData);
    const fetchDocumentApi = async () => {
      try {
        const response = await axios.post(createServices, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("response", response);
        toast.success(response.data.msg);
      } catch {}
    };
    fetchDocumentApi();
  }, [
    token,
    categoryId,
    categoryName,
    childCategoryDetailId,
    childCategoryDetailName,
    license,
    service,
  ]);

  // Handling Select Dropdowns Items
  const handleSelect = (event, value) => {
    if (value && !selectedItems.includes(value)) {
      setSelectedItems([...selectedItems, value]);
      setInputValue("");
    }
  };

  const handleRemove = (item) => {
    setSelectedItems(
      selectedItems.filter((selectedItem) => selectedItem !== item)
    );
  };

  return (
    <>
      <div className="profile__container">
        <div className="d-flex gap-3">
          <ArrowBackRounded className="back-arrow mt-2 fs-2 fw-bold" />
          <div className="">
            <h1 className="heading fw-bold gilroy">Add Services</h1>
            <p
              style={{ fontSize: "16px", fontWeight: 600, color: "#6A7D9F" }}
              className="m-0 gilroy"
            >
              Home / Products & Services / Add Services
            </p>
          </div>
        </div>
        <div
          style={{ backgroundColor: "#ffffff" }}
          className="border rounded mt-5 p-4"
        >
          <div className="d-flex gap-2 align-items-center">
            <ConstructionRounded style={{ color: "#275ea7" }} />
            <h2 className="service_catalouge gilroy">Services Catalogue</h2>
          </div>
          <div className="search_categories border rounded ps-3 w-50 my-3 d-flex align-items-center mx-auto ">
            <FontAwesomeIcon
              icon={faMagnifyingGlass}
              style={{ color: "#6A7D9F", fontSize: "20px" }}
            />
            <Autocomplete
              disablePortal
              options={top100Films}
              size="small"
              fullWidth={true}
              popupIcon={<KeyboardArrowDown sx={{ fontSize: "20px" }} />}
              onChange={handleSelect}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              renderInput={(params) => (
                <CustomTextField {...params} fullWidth={true} />
              )}
              clearOnBlur
            />
          </div>
          <Box sx={{ flexGrow: 1, marginTop: 5, marginBottom: 5 }}>
            <Grid container spacing={2}>
              {selectedItems.map((item, index) => (
                <Grid key={index} xs={4}>
                  <span className="search_selected__options mx-3 rounded d-flex flex-row align-items-center justify-content-between py-1 ps-2 pe-4">
                    <FontAwesomeIcon
                      icon={faXmark}
                      style={{ color: "#001e2f", fontSize: "10px" }}
                      onClick={() => handleRemove(item)}
                    />
                    <span className="search_seleted__title">{item.label}</span>
                  </span>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={5}>
              {dropDownContent &&
                dropDownContent.map((item, index) => (
                  <Grid key={index} xs={12} sm={6} lg={4}>
                    <div
                      className="service_category_dropdown__card d-flex flex-column justify-content-center align-items-center border p-2 pt-0 rounded"
                      onClick={() => handleCardClick(item)} // Handle card click here
                    >
                      <img
                        src={item.path}
                        alt={item.name}
                        className="profile_img"
                      />
                      <h4 className="service_card_title my-4 gilroy">
                        {item.name}
                      </h4>
                      <SubCategories
                        itemsData={item}
                        onSave={handleDocumentDetailsData}
                      />
                    </div>
                  </Grid>
                ))}
            </Grid>
          </Box>
        </div>
        <div className="d-flex justify-content-center w-100 border"></div>
      </div>
    </>
  );
};

export default AddServiceDetails;

const top100Films = [
  { label: "Fight Club", year: 1999 },

  { label: "Toy Story", year: 1995 },
  { label: "Bicycle Thieves", year: 1948 },
  { label: "The Kid", year: 1921 },
  { label: "Inglourious Basterds", year: 2009 },
  { label: "Snatch", year: 2000 },
  { label: "3 Idiots", year: 2009 },
  { label: "Monty Python and the Holy Grail", year: 1975 },
];
