import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./OnGoing.css";
import auctionImage from "../../../Rectangle 2057.png";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Button, AvatarGroup, Avatar, useMediaQuery } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { auctionContent } from "../../auctionsdata.js";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faChevronLeft,
  faChevronRight,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const itemsPerPage = 4;

function OnGoing() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/auctionDetails");
  };

  const [currentPage, setCurrentPage] = useState(0);

  // Screen size for responsiveness--------------------
  const isMediumScreen = useMediaQuery("(max-width: 1024px)");
  const isSmallScreen = useMediaQuery("(max-width:564px) ");

  const styleLabel = { fontSize: "16px" };
  const auctionInfo =
    "d-flex flex-column gap-2 gap-sm-3 w-100 justify-content-between align-items-start my-1 my-sm-3 mx-2 w-50";

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };
  const offset = currentPage * itemsPerPage;
  const currentItems = auctionContent.slice(offset, offset + itemsPerPage);

  return (
    <>
      <div>
        {currentItems &&
          currentItems.map((item) => (
            <div className="p-1 p-sm-4 border rounded my-4">
              <Container className=" px-0">
                <Row className="  px-0">
                  <Col
                    sm={12}
                    md={2}
                    className=" d-flex justify-content-center px-0"
                  >
                    <img
                      src={auctionImage}
                      alt={item.title}
                      style={{
                        width: isMediumScreen ? "8.8rem" : "10rem",
                        height: isMediumScreen ? "8.8rem" : "10rem",
                      }}
                    />
                  </Col>

                  <Col sm={12} md={10} className=" mt-2">
                    <Row className="w-100">
                      <Col sm={12} className=" ">
                        <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center w-100">
                          <h4 className="content-title mx-3 gilroy p-0">
                            {item.title}
                          </h4>
                          <div className="d-flex justify-content-between justify-content-sm-end w-100">
                            <span
                              className="text-lowercase mt-1"
                              style={{
                                color: "rgba(106, 125, 159, 1)",
                                fontSize: "14px",
                              }}
                            >
                              {item.orderTime}
                            </span>
                            <MoreVertIcon
                              style={{ fontSize: "35px", color: "#000" }}
                              className=""
                            />
                          </div>
                        </div>
                        <div className="d-flex gap-3 mt-1 mx-2">
                          <p
                            style={{
                              color: "rgba(0, 30, 47, 1)",
                              backgroundColor: "rgba(214, 236, 255, 1)",
                              textTransform: "none",
                              fontSize: "16px",
                              fontWeight: "500",
                              padding: " 2px 15px",
                            }}
                            size="small"
                            className="general-pest-control--btn m-0 rounded gilroy"
                          >
                            General Pest Control
                          </p>
                          <p
                            style={{
                              color: "rgba(0, 30, 47, 1)",
                              backgroundColor: "rgba(214, 236, 255, 1)",
                              textTransform: "none",
                              fontSize: "16px",
                              fontWeight: "500",
                              padding: " 2px 15px",
                            }}
                            size="small"
                            className="pest-control--btn rounded gilroy m-0"
                          >
                            Pest Control
                          </p>
                        </div>
                      </Col>
                      <Col sm={12} md={6}>
                        <div className={auctionInfo}>
                          <div className="avatar--location d-flex gap-2 align-items-center">
                            <Avatar
                              sx={{ width: 18, height: 18 }}
                              src={item.userImage}
                            />
                            <p
                              style={styleLabel}
                              className=" details gilroy m-0 "
                            >
                              {item.username}
                            </p>
                          </div>
                          <div className="d-flex gap-2 align-items-center">
                            <InfoIcon
                              className="icons-color"
                              sx={{ width: 18, height: 18 }}
                            />
                            <p
                              style={styleLabel}
                              className="details gilroy m-0 p-0 "
                            >
                              {item.serviceOrdered}
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col sm={12} md={6}>
                        <div className={auctionInfo}>
                          <div className="d-flex gap-2 align-items-center ">
                            <FontAwesomeIcon
                              icon={faMapMarkerAlt}
                              style={{ color: "#6a7d9f", fontSize: "18px" }}
                            />

                            <p
                              style={styleLabel}
                              className="details gilroy m-0 p-0 "
                            >
                              {item.location}
                            </p>
                          </div>
                          <div className="d-flex gap-2 align-items-center">
                            <FontAwesomeIcon
                              icon={faCalendarAlt}
                              style={{ color: "#6a7d9f", fontSize: "17px" }}
                            />
                            <p
                              style={styleLabel}
                              className="details gilroy m-0 p-0 "
                            >
                              {item.dateTime}
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={12} className=" ">
                    <div className="d-flex h-100 flex-column flex-md-row  justify-content-between align-items-start  align-item-md-center">
                      <div>
                        <div className="d-flex align-items-center gap-3 mt-4">
                          <div className="avatars_container d-flex">
                            {/* ----------------***************--------------- */}
                            <AvatarGroup total={3}>
                              <Avatar
                                alt="Remy Sharp"
                                src="/static/images/avatar/1.jpg"
                                sx={{ width: 20, height: 20 }}
                              />
                              <Avatar
                                alt="Travis Howard"
                                src="/static/images/avatar/2.jpg"
                                sx={{ width: 20, height: 20 }}
                              />
                              <Avatar
                                alt="Agnes Walker"
                                src="/static/images/avatar/4.jpg"
                                sx={{ width: 20, height: 20 }}
                              />
                              <Avatar
                                alt="Trevor Henderson"
                                src="/static/images/avatar/5.jpg"
                                sx={{ width: 20, height: 20 }}
                              />
                            </AvatarGroup>
                            {/* ----------------***************--------------- */}
                          </div>
                          <div className="d-flex gap-2">
                            <div className="bids-box px-3 py-2 rounded-pill">
                              <p className="bids m-0">
                                <span className="fw-bold">
                                  {item.statusBids}
                                </span>
                                &nbsp; Bids
                              </p>
                            </div>
                            <div className="timer  px-3 py-2 rounded-pill">
                              <p className="hours-left m-0">{item.status}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div sm={6} className="d-flex justify-content-end">
                        <div className="d-flex gap-1 gap-sm-3 mt-2 align-items-center">
                          <p className="budget-price gilroy text-center m-0 ">
                            Budget: <span>&#x20B9;{item.budget}</span>
                          </p>
                          <Box sx={{ "& button": { m: 1 } }}>
                            <Button
                              style={{
                                backgroundColor: "rgba(19, 101, 175, 1)",
                                color: "rgba(255,255,255,1)",
                                padding: "3px 25px",
                                fontSize: isSmallScreen ? "14px" : "18px",
                              }}
                              size="small"
                              onClick={handleClick}
                              className="place-bid-button text-capitalize"
                            >
                              Place a Bid
                            </Button>
                          </Box>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          ))}
      </div>
      <ReactPaginate
        previousLabel={
          // <ChevronBack color="#001b3d" width="18px" height="18px" />
          <FontAwesomeIcon
            icon={faChevronLeft}
            style={{ color: "#001b3d", fontSize: "16px" }}
          />
        }
        nextLabel={
          // <ChevronForward color="#001b3d" width="18px" height="18px" />
          <FontAwesomeIcon
            icon={faChevronRight}
            style={{ color: "#001b3d", fontSize: "16px" }}
          />
        }
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={Math.ceil(auctionContent.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
        pageClassName="pt-1"
        previousClassName=" pt-1"
        nextClassName=" pt-1"
      />
    </>
  );
}

export default OnGoing;
