import React, { useEffect } from "react";
import "./Bids.css";
import FilterBids from "./FilterBids";
import SortBids from "./SortBids";
import BidderContainer from "./BidderContainer.jsx";

function Bids() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="d-flex gap-5 justify-content-end w-100 mt-3">
        <div className="d-flex flex-column align-items-center">
          <span className="info-bids gilroy">&#8377;700</span>
          <p>Avarage Bids</p>
        </div>
        <div className="d-flex flex-column align-items-center">
          <span className="info-bids gilroy">40</span>
          <p>No. of Bids</p>
        </div>
      </div>
      <div className="bids-filter-sort d-flex justify-content-between my-4">
        <FilterBids />
        <SortBids />
      </div>
      <div className="bidder-container">
        <BidderContainer />
      </div>
    </>
  );
}

export default Bids;
