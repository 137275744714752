import React from "react";
import "./Newslater.css";
import { Button, TextField } from "@mui/material";

const Newslater = () => {
  // const isSmallScreen = useMediaQuery("(max-width:768px)");
  // const matches = useMediaQuery("(min-width:576px)");
  return (
    <>
      <div className="newslater__containter d-flex flex-column justify-content-center align-items-center mt-5">
        <div className="newslater__content  mt-0 px-2 mx-auto">
          <div className="newlater__headings-container d-flex flex-column justify-content-center align-items-center">
            <h1 className="newslater__heading gilroy fw-bold">
              Subscribe to our newsletter!
            </h1>
            <div className="horizontal__line border-top-0 mt-1" />
            <p className="newslater__subheading text-wrap gilroy">
              Stay in the loop with our latest updates, exclusive offers, and
              exciting news by subscribing to our newsletter. Join our community
              and be the first to know about latest news
            </p>
          </div>
          <div className="subscribe__email d-flex flex-column flex-md-row align-items-center gap-2 justify-content-center">
            <div className="email_to_subscribe">
              <TextField
                size="small"
                fullWidth
                style={{
                  color: "#6A7D9F",
                  background: "#ffffff",
                  borderRadius: "4px",
                }}
                placeholder="Enter Your Email ID"
              />
            </div>
            <Button
              varient="contained"
              sx={{
                background: "#FA9B00",
                textTransform: "none",
                fontSize: "20px",
                fontWeight: 500,
                lineHeight: "23.54px",
                padding: "8px 15px",
              }}
            >
              Subscribe
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Newslater;
