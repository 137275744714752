import React from "react";
import "./AuctionsCarousel.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { carousels } from "./auctionsdata.js";
import { responsive } from "./auctionsdata.js";
import Auctions from "./Auctions.jsx";

const AuctionsCarousel = () => {
  const carouselItems = carousels.map((carousel) => (
    <Auctions
      id={carousel.id}
      updateTime={carousel.updateTime}
      timeLeft={carousel.timeLeft}
      title={carousel.title}
      name={carousel.name}
      orderId={carousel.orderId}
      information={carousel.information}
      location={carousel.location}
      date={carousel.date}
      price={carousel.price}
      budget={carousel.budget}
      image={carousel.image}
      bids={carousel.bids}
    />
  ));
  return (
    <Carousel
      swipeable={false}
      draggable={false}
      showDots={true}
      responsive={responsive}
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={1000}
      transitionDuration={1000}
      removeArrowOnDeviceType={["desktop", "mobile", "tablet"]}
    >
      {carouselItems}
    </Carousel>
  );
};

export default AuctionsCarousel;
