import React, { useEffect, useState } from "react";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  Button,
  Link,
  useMediaQuery,
  FormHelperText,
} from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";

const AddNewTaxAssessment = ({ panValue }) => {
  const [pan, setPan] = useState("");
  const [itrType, setItrType] = useState("");
  const [assessmentYear, setAssessmentYear] = useState("");
  const [acknowledgementNumber, setAcknowledgementNumber] = useState("");
  const [profitGain, setProfitGain] = useState("");
  const [salesGrossReceipts, setSalesGrossReceipts] = useState("");

  // Screen Size for responsivness ---------
  const isBetween768And883 = useMediaQuery(
    "(min-width: 769px) and (max-width: 883px)"
  );
  const isLargeScreen = useMediaQuery("(max-width: 992px) ");

  // Styling part --------------
  const labelStyle = {
    fontSize: isLargeScreen ? "14px" : "16px",
    textTransform: "none",
  };
  const labelClassStyle = "fw-bold my-auto ms-2 mt-2 gilroy-regular text-dark";
  const sectionStyle = `d-flex flex-column flex-sm-row ${
    isBetween768And883 ? "flex-md-column" : "flex-md-row"
  } gap-3 gap-lg-5 justify-content-between align-items-center`;
  // Styling part ends ------------------------

  useEffect(() => {
    if (panValue) {
      setPan(panValue.panNo);
    }
  }, [panValue]);

  const handlePanData = (event) => {
    if (!panValue) {
      setPan(event.target.value);
    }
  };

  const handleItrTypeChange = (event) => {
    setItrType(event.target.value);
  };

  const handleAssessmentYearChange = (event) => {
    setAssessmentYear(event.target.value);
  };

  const handleSubmit = () => {
    const taxAssessmentData = {
      pan,
      itrType,
      assessmentYear,
      acknowledgementNumber,
      profitGain,
      salesGrossReceipts,
    };

    const existingKeywords = localStorage.getItem("taxAssessmentData");
    let keywords =
      existingKeywords !== undefined ? JSON.parse(existingKeywords) : [];

    if (!Array.isArray(keywords)) {
      keywords = [];
    }

    if (!keywords.includes(taxAssessmentData)) {
      keywords.push(taxAssessmentData);
    }

    localStorage.setItem("taxAssessmentData", JSON.stringify(keywords));
    // console.log("new Tax Assessment", keywords);

    setItrType("");
    setAcknowledgementNumber("");
    setAssessmentYear("");
    setProfitGain("");
    setSalesGrossReceipts("");
  };

  return (
    <>
      <div className="add_new_assessment__container">
        <div
          style={{
            backgroundColor: "#FFA5A5",
            fontSize: "13px",
            fontWeight: 500,
            color: "#000000",
          }}
          className="add_new_assessment_note rounded p-3"
        >
          <p className="m-0 pb-1 gilroy">
            Select appropriate assessment year from the dropdown. For each of
            the assessment years enter the information in the subsequent columns
            as follows:
          </p>
          <ul className="gilroy-regular">
            <li>
              Acknowledgement No. for ITR - Enter the correct Acknowledge No. in
              this field as submitted in the ITR.
            </li>
            <li>
              Sale/Gross Receipts of Business - Net of returns and refunds and
              duty of taxes if any as submitted in your ITR.
            </li>
            <li>
              Profit and gain from business or profession as submitted in your
              ITR.
            </li>
          </ul>
        </div>
        <div
          style={{ backgroundColor: "#E4F5FF" }}
          className="d-flex flex-column flex-sm-row gap-2 gap-sm-5 w-100 p-3 mt-5 rounded"
        >
          <p style={{ fontSize: "12px" }} className="m-0 gilroy-regular">
            Information entered here shall be validated with your ITR records.
            Check the document for instruction.
          </p>
          <Link style={{ fontSize: "12px" }}>
            <InsertDriveFileOutlinedIcon fontSize="small" /> ITR Guideline
            Document
          </Link>
        </div>
        <div className={`pan--itr_type ${sectionStyle} mt-5`}>
          <div className="w-100">
            {/* AutoFilled and Not Editable only readable */}
            <p style={labelStyle} className={labelClassStyle}>
              PAN*
            </p>
            <TextField
              sx={{
                "& .Mui-disabled": {
                  cursor: "not-allowed",
                },
              }}
              size="small"
              fullWidth
              disabled
              value={pan}
              onChange={handlePanData}
            />
          </div>
          <div className="w-100">
            <p style={labelStyle} className={labelClassStyle}>
              ITR Type*
            </p>
            <FormControl fullWidth>
              <Select
                value={itrType}
                onChange={handleItrTypeChange}
                size="small"
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                IconComponent={KeyboardArrowDown}
              >
                <MenuItem value="10">Ten</MenuItem>
                <MenuItem value="20">Twenty</MenuItem>
                <MenuItem value="30">Thirty</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div
          className={`assessment_year--acknowledgement_no_for_itr ${sectionStyle} mt-5`}
        >
          <div className="w-100">
            <p style={labelStyle} className={labelClassStyle}>
              Assessment Year*
            </p>
            <FormControl fullWidth>
              <Select
                value={assessmentYear}
                onChange={handleAssessmentYearChange}
                size="small"
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                IconComponent={KeyboardArrowDown}
              >
                <MenuItem value="10">Ten</MenuItem>
                <MenuItem value="20">Twenty</MenuItem>
                <MenuItem value="30">Thirty</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="w-100">
            <p style={labelStyle} className={labelClassStyle}>
              Acknowledgement number for ITR*
            </p>
            <TextField
              size="small"
              fullWidth
              placeholder="Enter Acknowledgement no. for ITR"
              value={acknowledgementNumber}
              onChange={(e) => setAcknowledgementNumber(e.target.value)}
            />
          </div>
        </div>
        <div
          className={`profit-gain_from_business--sales-gross_receipts_of_business ${sectionStyle} mt-5`}
        >
          <div className="w-100">
            <p style={labelStyle} className={labelClassStyle}>
              Profit/Gain from Business/Profession*
            </p>
            <TextField
              size="small"
              fullWidth
              placeholder="Enter Profit/Gain from Business/Profession"
              value={profitGain}
              onChange={(e) => setProfitGain(e.target.value)}
            />
            <FormHelperText></FormHelperText>
          </div>
          <div className="w-100">
            <p style={labelStyle} className={labelClassStyle}>
              Sales/Gross Receipts of Business*
            </p>
            <TextField
              size="small"
              fullWidth
              placeholder="Enter Sales/Gross Receipts of Business"
              value={salesGrossReceipts}
              onChange={(e) => setSalesGrossReceipts(e.target.value)}
            />
            <FormHelperText></FormHelperText>
          </div>
        </div>
        <div className="mt-4 float-end">
          <Button
            variant="contained"
            style={{ backgroundColor: "#fa9b00", color: "#ffffff" }}
            onClick={handleSubmit}
          >
            Create
          </Button>
        </div>
      </div>
    </>
  );
};

export default AddNewTaxAssessment;
